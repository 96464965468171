import { PaginationOrder } from '@app/domain/PaginationModel';
import { SubmissionModel } from '@app/domain/SubmissionModel';
import { getFirebaseFirestore } from '@app/services/firebase.service';
import {
  FirestoreError,
  QueryConstraint,
  QuerySnapshot,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';

export const SUBMISSION_COUNT_PER_PAGE = 10;

export type PaginationSortBy = 'createdAt';

export interface Pagination {
  page: number;
  sortBy: PaginationSortBy;
  order: PaginationOrder;
}

export interface SubmissionListRequest extends Pagination {
  formId: string;
  limit: number;
  observer?: (snapShot: QuerySnapshot) => void;
  onError?: (error: FirestoreError) => void;
}

export interface SubmissionTableRow extends SubmissionModel {
  id: string;
  [fieldName: string]: unknown;
}

export const getSubmissionsCount = async () => {
  const _SubmissionsFCRef = collection(getFirebaseFirestore(), 'submissions');
  return (await getCountFromServer(query(_SubmissionsFCRef))).data().count;
};

export const getSubmissionList = (payload: SubmissionListRequest) => {
  // console.log(payload);
  const _SubmissionsFCRef = collection(getFirebaseFirestore(), 'submissions');
  const _queryConstraint: QueryConstraint[] = [];
  // if (payload.sortBy) {
  //   _queryConstraint.push(orderBy(payload.sortBy, payload.order));
  // }

  _queryConstraint.push(where('formId', '==', payload.formId));

  const q = query(_SubmissionsFCRef, ..._queryConstraint, limit(payload.limit * payload.page));
  return onSnapshot(
    q,
    payload.observer ??
      (() => {
        return;
      }),
    payload.onError ??
      (() => {
        return;
      }),
  );
};

export const getSubmissionsAll = async (formId: string) => {
  // console.log(payload);
  const _SubmissionsFCRef = collection(getFirebaseFirestore(), 'submissions');

  const q = query(_SubmissionsFCRef, where('formId', '==', formId));
  return await getDocs(q);
};
