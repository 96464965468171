import { httpApi } from '@app/api/http.api';
import { FormModel } from '@app/domain/FormModel';
import { PaginationOrder } from '@app/domain/PaginationModel';
import { UploadResponse } from '@app/domain/UploadResponse';
import { getFirebaseFirestore } from '@app/services/firebase.service';
import {
  FirestoreError,
  QueryConstraint,
  QuerySnapshot,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

export const FORM_COUNT_PER_PAGE = 10;

export type PaginationSortBy = 'order' | 'name' | 'createdAt' | 'updatedAt';

export interface Pagination {
  page: number;
  sortBy: PaginationSortBy;
  order: PaginationOrder;
}

export interface FormListRequest {
  page: number;
  limit: number;
  sortBy: PaginationSortBy;
  order: PaginationOrder;
  observer?: (snapShot: QuerySnapshot) => void;
  onError?: (error: FirestoreError) => void;
}

export type DynamicFormData = Omit<FormModel, 'thumbnail' | 'submissions' | 'createdAt'>;

export interface FormTableRow extends FormModel {
  id: string;
  deletedAt?: string;
}

export interface FormDetailRequest {
  id: string;
}

export const getFormsCount = async () => {
  const _FormsFCRef = collection(getFirebaseFirestore(), 'forms');
  return (await getCountFromServer(query(_FormsFCRef))).data().count;
};

export const getFormList = (payload: FormListRequest) => {
  // console.log(payload);
  const _FormsFCRef = collection(getFirebaseFirestore(), 'forms');
  const _queryConstraint: QueryConstraint[] = [];
  if (payload.sortBy) {
    _queryConstraint.push(orderBy(payload.sortBy, payload.order));
  }

  const q = query(_FormsFCRef, ..._queryConstraint, limit(payload.limit * payload.page));
  return onSnapshot(
    q,
    payload.observer ??
      (() => {
        return;
      }),
    payload.onError ??
      (() => {
        return;
      }),
  );
};

export const getFormDetail = async (payload: FormDetailRequest) => {
  const _FormDetailDocRef = doc(getFirebaseFirestore(), 'forms', `${payload.id}`);
  const _doc = await getDoc(_FormDetailDocRef);
  if (_doc.exists()) {
    const _data = _doc.data();
    const _res: FormTableRow = {
      id: _doc.id,
      name: _data.name,
      order: _data.order,
      submissions: _data.submissions,
      status: _data.status,
      fields: _data.fields,
      pages: _data.pages,
      thumbnail: _data.thumbnail,
      createdAt: _data.createdAt,
      updatedAt: _data.updatedAt,
      preText: _data.preText,
      postText: _data.postText,
      submissionMessage: _data.submissionMessage,
      submissionReceivers: _data.submissionReceivers,
      subtitle: _data.subtitle,
      buttonText: _data.buttonText,
    };
    return _res;
  }
  return null;
};

export const getHomeFormCount = async () => {
  const _FormsFCRef = collection(getFirebaseFirestore(), 'forms');
  return (await getCountFromServer(query(_FormsFCRef, where('pages', 'array-contains', 'home')))).data().count;
};

export const createForm = async (payload: DynamicFormData) =>
  httpApi.post(`/forms`, { ...payload }).then(({ data }) => data);

export const updateForm = async (id: string, payload: DynamicFormData) =>
  httpApi.put(`/forms/${id}`, { ...payload }).then(({ data }) => data);

export const updateFormThumbnail = async (id: string, payload: FormData) =>
  httpApi.patch<UploadResponse>(`/forms/${id}/thumbnail`, payload).then(({ data }) => data);

export const deleteForm = (id: string): Promise<undefined> =>
  httpApi.delete<undefined>(`/forms/${id}`).then(({ data }) => data);
