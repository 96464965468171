import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { persistRole, persistUser, readRole, readUser } from '@app/services/localStorage.service';
import {
  activeUser,
  bulkDeleteUser,
  deleteUser,
  disableUser,
  getUserList,
  newUser,
  NewUserData,
  updateUser,
  UpdateUserData,
  UserListRequest,
} from '@app/api/users.api';

export interface UserState {
  user: UserModel | null;
  isSuperAdmin: boolean;
  users: UserModel[] | null;
}

const initialState: UserState = {
  user: readUser(),
  isSuperAdmin: readRole(),
  users: [],
};

// export const getUsers = createAsyncThunk('user/list', async (loginPayload: UserListRequest, { dispatch }) =>
//   getUserList(loginPayload).then((res) => {
//     dispatch(res.data);
//     return res.data;
//   }),
// );

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});
export const setRole = createAction<PrepareAction<boolean>>('user/setRole', (isSuperAdmin: boolean) => {
  persistRole(isSuperAdmin);

  return {
    payload: isSuperAdmin,
  };
});

export const doDisableUser = createAsyncThunk('user/doDisableUser', async (id: string) => disableUser(id));

export const doActiveUser = createAsyncThunk('user/doActiveUser', async (id: string) => activeUser(id));

export const doDeleteUser = createAsyncThunk('user/doDeleteUser', async (uid: string) => deleteUser(uid));

export const doAddUser = createAsyncThunk('user/doAddUser', async (newUserData: NewUserData) => newUser(newUserData));

export const doUpdateUser = createAsyncThunk('user/doUpdateUser', async (updateUserData: UpdateUserData) =>
  updateUser(updateUserData.uid, updateUserData),
);

export const doBulkDeleteUser = createAsyncThunk('user/doBulkDeleteUser', async (ids: number[]) =>
  bulkDeleteUser(ids.join(',')),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(setRole, (state, action) => {
      state.isSuperAdmin = action.payload;
    });
    // builder.addCase(getUsers.fulfilled, (state, action) => {
    //   state.users = action.payload;
    // });
  },
});

export default userSlice.reducer;
