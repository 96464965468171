import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PlusOutlined } from '@ant-design/icons';
import { doUpdateFormField } from '@app/store/slices/formSlice';
import { FieldModel, fieldTypeList, FieldType as FieldDataType, FlatFieldData } from '@app/domain/FormModel';
import * as S from './UpdateFieldModal.styles';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Languages } from '@app/components/Languages';

interface UpdateFieldModalProps {
  index: number;
  data: FieldModel | undefined;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

const initialInfoValues: FlatFieldData = {
  label: '',
  label_en: '',
  name: '',
  type: 'text',
  options: [],
};

const defaultFields: FieldModel[] = [];

export const UpdateFieldModal: React.FC<UpdateFieldModalProps> = ({ data, index, open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const general = useAppSelector((state) => state.general.general);
  const { fields } = useAppSelector((state) => state.form.formData ?? { fields: defaultFields });

  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState(data?.type);

  const [language, setLanguage] = useState('en');
  const prevLanguage = useRef<string>('en');

  const [options, setOptions] = useState(data?.__options || {});
  const [isTranslationMode, setIsTranslationMode] = useState(false);

  useEffect(() => {
    setOptions({ ...options, [prevLanguage.current]: form.getFieldValue('options') });

    const __options = { ...options, [prevLanguage.current]: form.getFieldValue('options') };
    form.setFieldValue('options', __options[language] ?? __options['en']);
    prevLanguage.current = language;
    setIsTranslationMode(language !== 'en');
  }, [language]);

  useResetFormOnCloseModal({
    form,
    open,
  });

  useEffect(() => {
    if (data) {
      setType(data.type);
    }
  }, [data]);

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: FlatFieldData) => {
    setLoading(true);

    const _trans = general?.languages
      ? Object.fromEntries(
          general.languages
            .filter((e) => e.code != 'en')
            .map((_language) => [_language.code, `${values[`label_${_language.code}`] ?? ''}`])
            .filter((e) => e[1] !== ''),
        )
      : undefined;

    const __options = { ...options, [prevLanguage.current]: form.getFieldValue('options') };
    const _fieldData: FieldModel = {
      label: values['label_en'] ? `${values['label_en']}` : values.label,
      name: values.name,
      type: values.type,
      options: __options['en'],
      __options: __options,
      trans: _trans,
    };

    dispatch(doUpdateFormField({ index, field: _fieldData }))
      .unwrap()
      .then(() => {
        setLoading(false);
        onFinish();
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const fieldFormValues: FlatFieldData = useMemo(
    () =>
      data
        ? {
            label: data.label,
            name: data.name,
            type: data.type,
            options: data.options,
            label_en: data.label,
            ...(data.trans
              ? Object.fromEntries(
                  Object.keys(data.trans).map((transKey) => [`label_${transKey}`, data.trans?.[transKey]]),
                )
              : {}),
          }
        : initialInfoValues,
    [data],
  );

  const isValidateKey = (key: string, isInArray = false, arrayIndex = -1): boolean => {
    if (!fields) return false;

    const _fields = fields.slice(0);
    if (isInArray) {
      _fields.splice(arrayIndex, 1);
    }
    const res = _fields.find((_value) => _value.name === key);
    if (res) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Modal title="Update Field" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <Languages selected={language} onChange={setLanguage} />
      <BaseForm initialValues={fieldFormValues} form={form} layout="vertical" name="CreateForm" onFinish={handleSubmit}>
        {general?.languages.map((_language) => (
          <BaseForm.Item
            hidden={_language.code !== language}
            key={`label_${_language.code}`}
            name={`label_${_language.code}`}
            label="Label"
            rules={_language.code === 'en' ? [{ required: true, message: t('common.requiredField') }] : []}
          >
            <Input />
          </BaseForm.Item>
        ))}
        <BaseForm.Item
          name="name"
          label="Key"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              validator: async (_, name) => {
                if (!isValidateKey(name, true, index)) {
                  return Promise.reject(new Error(t('forms.updateForm.KeyUnique')));
                }
              },
            },
          ]}
        >
          <Input disabled={isTranslationMode} />
        </BaseForm.Item>
        <S.HintText>{t('common.fieldKeyHint')}</S.HintText>
        <BaseForm.Item
          label="Type"
          name={'type'}
          key={'type'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Select
            disabled={isTranslationMode}
            onChange={(_data) => {
              _data && setType(_data as FieldDataType);
            }}
          >
            {fieldTypeList.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </BaseForm.Item>
        {type === 'multiple-choice' || type === 'single-choice' ? (
          <>
            <S.Label>{t('forms.updateForm.options')}</S.Label>
            <BaseForm.List
              name="options"
              rules={[
                {
                  validator: async (_, options) => {
                    if (!options || options.length < 1) {
                      return Promise.reject(new Error(t('forms.updateForm.optionsEmpty')));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => {
                    return (
                      <S.Wrapper key={field.key}>
                        <BaseForm.Item
                          key={field.key}
                          label={`Option ${index + 1}`}
                          name={field.name}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: t('forms.updateForm.optionRequired'),
                            },
                          ]}
                        >
                          <Input />
                        </BaseForm.Item>
                        {fields.length > 1 ? <S.RemoveBtn onClick={() => remove(field.name)} /> : null}
                      </S.Wrapper>
                    );
                  })}

                  <BaseForm.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('forms.updateForm.addOption')}
                    </Button>
                  </BaseForm.Item>
                </>
              )}
            </BaseForm.List>
          </>
        ) : null}
      </BaseForm>
    </Modal>
  );
};
