import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  me,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  UpdateMeRequest,
  updateMe,
  changePassword,
  ChangePasswordData,
  ResendVerificationRequest,
  resendVerificationEmail,
} from '@app/api/auth.api';
import { setRole, setUser } from '@app/store/slices/userSlice';
import { deleteRole, deleteToken, deleteUser, persistToken, readToken } from '@app/services/localStorage.service';
import { firebaseSignOut } from '@app/services/firebase.service';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/admin/login', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then(async (res) => {
    // console.log(res);
    const _user = loginPayload.user;
    const _res = await _user.getIdTokenResult();
    const _displayName = _res.claims.displayName as string;
    const _userPayload = {
      uid: _user.uid,
      email: _user.email ?? '',
      displayName: _displayName ?? '',
    };
    const _isSuperAdmin = res.roles.includes('super-admin');
    // fetch user data and save to local
    dispatch(setUser(_userPayload));
    dispatch(setRole(_isSuperAdmin));
    // persistUser(_userPayload);
    persistToken(res.accessToken);

    return res.accessToken;
  }),
);

export const getMe = createAsyncThunk('auth/me', async (undefined, { dispatch }) =>
  me().then((res) => {
    // dispatch(setUser(res.user));
    // persistToken(res.token);

    return res.accessToken;
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doChangePassword = createAsyncThunk('auth/doChangePassword', async (data: ChangePasswordData) =>
  changePassword(data),
);

export const doUpdateMe = createAsyncThunk('auth/doUpdateMe', async (data: UpdateMeRequest, { dispatch }) =>
  updateMe(data).then((res) => {
    dispatch(setUser(res));

    return res;
  }),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  deleteRole();
  dispatch(setUser(null));
  firebaseSignOut();
});

export const doResendVerificationEmail = createAsyncThunk(
  'auth/doResendVerificationEmail',
  async (data: ResendVerificationRequest) => resendVerificationEmail(data),
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = null;
    });
    builder.addCase(doUpdateMe.fulfilled, (state) => {
      // state.token = '';
    });
  },
});

export default authSlice.reducer;
