import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import { doCreateManual } from '@app/store/slices/manualSlice';

interface CreateManualModalModalProps {
  sectionId?: string;
  sectionName?: string;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface NewFormData {
  name: string;
  files: File[];
}

export const CreateManualModal: React.FC<CreateManualModalModalProps> = ({
  sectionId,
  sectionName,
  open,
  onCancel,
  onFinish,
}) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: NewFormData) => {
    const _file: File | any = values.files[0];
    // console.log(_file);
    if (!sectionId || !_file) {
      notificationController.error({ message: t('common.requiredField') });
      return;
    }

    const _params = {
      sectionId: sectionId,
      name: values.name,
      file: _file?.originFileObj ?? _file,
    };
    setLoading(true);
    dispatch(doCreateManual(_params))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Create new manual',
            description: 'Successfully',
          });
          onFinish();
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    // console.log(file);
    // const fmData = new FormData();

    form.setFieldsValue({ files: [file] });

    // console.log((fmData.get('file') as File).name);

    try {
      // const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      onSuccess('Ok');
      // form.setFieldsValue({ files: [fmData.get('file')] });
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <Modal
      title={`New manual for ${sectionName}`}
      open={open}
      onOk={onOk}
      onCancel={onFormCancel}
      confirmLoading={isLoading}
    >
      <BaseForm form={form} layout="vertical" name="NewManual" onFinish={handleSubmit}>
        <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Upload manual"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[
            // { required: true, message: t('common.requiredField') },
            {
              validator: async (_, files) => {
                if (!files || files.length < 1) {
                  return Promise.reject(new Error(t('common.requiredField')));
                }
              },
            },
          ]}
        >
          <Upload
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
            maxCount={1}
            customRequest={dummyRequest}
            listType="picture"
          >
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
