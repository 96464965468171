import React, { useState } from 'react';
import { Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
import { UserTableRow } from '@app/api/users.api';
import { Avatar } from '@app/components/common/Avatar/Avatar';
import defaultAvatar from 'assets/images/default-avatar.jpg';
import { useMounted } from '@app/hooks/useMounted';

interface AddUserFormModalProps {
  data: UserTableRow;
  open: boolean;
  onCancel: () => void;
}

export const UserDetailModal: React.FC<AddUserFormModalProps> = ({ open, onCancel, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [isLoading, setLoading] = useState(false);
  // const [categories, setCategories] = useState<OptionValue[]>([]);
  // const [cateSelected, setCateSelected] = useState<OptionValue>();
  // const [progress, setProgress] = useState(0);

  useResetFormOnCloseModal({
    form,
    open,
  });

  // const onOk = () => {
  //   form.submit();
  // };

  return (
    <Modal
      open={open}
      title="Detail user"
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <BaseForm layout="vertical">
        <BaseForm.Item label="Name">
          <Space size="middle">
            <Avatar shape="circle" src={defaultAvatar} />
            <span>{data.displayName}</span>
          </Space>
        </BaseForm.Item>
        <BaseForm.Item label={t('common.email')}>{data.email}</BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
