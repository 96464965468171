import React from 'react';
import * as S from './Languages.styles';
import { Radio } from 'antd';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface Option {
  code: string;
  name: string;
}

interface LanguagesProps {
  selected: string;
  onChange: (code: string) => void;
  extraOptions?: Option[];
}

export const Languages: React.FC<LanguagesProps> = ({ onChange, selected, extraOptions }) => {
  const general = useAppSelector((state) => state.general.general);

  return (
    <S.Wrapper>
      <Radio.Group size="small" value={selected} onChange={(e) => onChange(e.target.value)}>
        {general?.languages.map((item) => (
          <Radio.Button key={item.code} value={item.code}>
            {item.name}
          </Radio.Button>
        ))}
        {extraOptions?.map((item) => (
          <Radio.Button key={item.code} value={item.code}>
            {item.name}
          </Radio.Button>
        ))}
      </Radio.Group>
    </S.Wrapper>
  );
};
