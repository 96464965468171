import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import { doUpdateManual } from '@app/store/slices/manualSlice';
import { ManualTableRow } from '@app/api/manual.api';
import { getTranslations } from '@app/api/general.api';
import { Languages } from '@app/components/Languages';
import * as S from '@app/components/manual/UploadBannerModal/UploadBannerModal.styles';

interface UpdateManualModalProps {
  data?: ManualTableRow;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface NewFormData {
  name: string;
  files: File[];
}

const initialData = {
  name: '',
  files: [],
};

export const UpdateManualModal: React.FC<UpdateManualModalProps> = ({ data, open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en');

  useResetFormOnCloseModal({
    form,
    open,
  });

  useEffect(() => {
    if (open && data) {
      form.setFieldsValue({ ...data });
      fetchTranslations();
    }
  }, [open, data]);

  useEffect(() => {
    const values = translations?.[language] ?? {};
    form.resetFields();
    form.setFieldsValue({ ...values });
  }, [language]);

  const fetchTranslations = async () => {
    if (data && data.id) {
      const translations = await getTranslations(`/manuals/${data.sectionId}/files`, data.id);
      translations['en'] = { ...data };
      setTranslations(translations);
      setLanguage('en');
    }
  };

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: NewFormData) => {
    // console.log(values, data);
    const _file: File | any = values.files[0];
    if (!data?.id || !data.sectionId) {
      notificationController.error({ message: t('common.requiredField') });
      return;
    }

    const _params = {
      language,
      sectionId: data.sectionId,
      fileId: data.id,
      name: values.name,
      file: _file?.originFileObj ?? _file,
    };
    setLoading(true);
    dispatch(doUpdateManual(_params))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Update manual',
            description: 'Successfully',
          });
          onFinish();
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    // console.log(file);

    // const fmData = new FormData();

    form.setFieldsValue({ files: [file] });

    // console.log((fmData.get('file') as File).name);

    try {
      // const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      onSuccess('Ok');
      // form.setFieldsValue({ files: [fmData.get('file')] });
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <Modal title="Update Manual" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <Languages selected={language} onChange={setLanguage} />
      <BaseForm form={form} initialValues={initialData} layout="vertical" name="UpdateManual" onFinish={handleSubmit}>
        <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Upload manual"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
        >
          <Upload
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
            maxCount={1}
            customRequest={dummyRequest}
            listType="picture"
          >
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
            <S.HintText>File Path: {translations?.[language]?.path}</S.HintText>
          </Upload>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
