import { httpApi } from '@app/api/http.api';
import { PaginationOrder } from '@app/domain/PaginationModel';
import { UserModel } from '@app/domain/UserModel';
import { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

export type PaginationSortBy = 'name' | 'email' | 'createdAt';
export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  defaultPageSize?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
}

export interface UserListRequest {
  page?: number;
  limit?: number;
  sortBy?: PaginationSortBy;
  order?: PaginationOrder;
  lastVisible?: DocumentSnapshot;
  observer?: (snapShot: QuerySnapshot) => void;
}

export interface UserListResponse {
  data: UserModel[];
  hasNextPage: boolean;
  pagination: Pagination;
}

export interface Status {
  id: number;
  name?: string;
}

export interface Role {
  id: number;
  name?: string;
}

export interface UserTableRow {
  uid: string;
  email: string;
  displayName: string;
  createdAt?: string;
  // updatedAt: string;
  // deletedAt: string;
}

export interface UpdateUserData {
  uid: string;
  displayName?: string;
  password?: string;
}
export interface NewUserData {
  displayName: string;
  email: string;
  password: string;
}

export const getUserList = () => {
  return httpApi.get('/users');
};

export const getVideosWatched = async (userId: number) => httpApi.get(`/users/watched/${userId}`);

export const updateUser = async (uid: string, payload: UpdateUserData) =>
  httpApi.patch(`/users/${uid}`, { ...payload }).then(({ data }) => data);

export const newUser = async (payload: NewUserData) => httpApi.post(`/users`, { ...payload }).then(({ data }) => data);

export const disableUser = async (id: string) => httpApi.patch(`/users/disable/${id}`);

export const activeUser = async (id: string) => httpApi.patch(`/users/active/${id}`);

export const deleteUser = async (uid: string) => httpApi.delete(`/users/${uid}`);

export const bulkDeleteUser = async (ids: string) => httpApi.delete(`/users/delete-multi/${ids}`);
