import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import * as S from './FieldsPreview.styles';
import { FieldModel, FieldType } from '@app/domain/FormModel';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { Col, Row } from 'antd';

interface FieldsPreviewProps {
  lang: string;
  fields: FieldModel[];
}

export const FieldsPreview: React.FC<FieldsPreviewProps> = ({ lang, fields }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  }, [fields]);

  const renderInput = (type: FieldType, options?: string[]) => {
    switch (type) {
      case 'text':
        return <Input />;
      case 'paragraph':
        return <TextArea rows={4} />;
      case 'single-choice':
        return (
          <Select>
            {options?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        );
      case 'multiple-choice':
        return (
          <Select mode="multiple" allowClear>
            {options?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        );
      case 'number':
        return <InputNumber />;
      default:
        return <Input />;
    }
  };

  const renderFields = () => {
    const _list: React.ReactNode[] = [];
    const _row: React.ReactNode[] = [];
    let i = 0; // count col in row

    fields.map((field, fieldIndex) => {
      const label = field.trans?.[lang] || field.label;
      if (field.type === 'paragraph') {
        if (_row.length > 0) {
          _list.push(
            <>
              {_row.map((_rowItem, index) => (
                <Col key={`FieldsPreview_${fieldIndex}_${index}`} span={12}>
                  {_rowItem}
                </Col>
              ))}
            </>,
          );
          _row.splice(0, _row.length);
          i = 0;
        }
        _list.push(
          <Col span={24}>
            <BaseForm.Item key={`FieldsPreview_${field.name}`} name={field.name} label={label}>
              {renderInput(field.type, field.__options?.[lang] || field.options)}
            </BaseForm.Item>
          </Col>,
        );
        return;
      }
      _row.push(
        <BaseForm.Item key={`FieldsPreview_${field.name}`} name={field.name} label={label}>
          {renderInput(field.type, field.__options?.[lang] || field.options)}
        </BaseForm.Item>,
      );
      i++;
      if (i >= 2 || fieldIndex === fields.length - 1) {
        i = 0;
        _list.push(
          <>
            {_row.map((_rowItem, index) => (
              <Col key={`FieldsPreview_${fieldIndex}_${index}`} span={12}>
                {_rowItem}
              </Col>
            ))}
          </>,
        );
        _row.splice(0, _row.length);
      }
      return;
    });
    return _list.map((_rowItem, index) => (
      <Row key={`FieldsPreview_row_${index}`} gutter={[30, 0]}>
        {_rowItem}
      </Row>
    ));
  };

  return (
    <BaseForm
      //  style={{ marginTop: '1rem', marginBottom: '1rem' }}
      form={form}
      name="FieldsPreview"
      contentEditable={false}
    >
      {/* {fields.map((field) => {
        return (
          <BaseForm.Item key={`FieldsPreview_${field.name}`} name={field.name} label={field.label}>
            {renderInput(field.type, field.options)}
          </BaseForm.Item>
        );
      })} */}
      {renderFields()}
    </BaseForm>
  );
};
