import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notificationController } from '@app/controllers/notificationController';
import { Unsubscribe } from 'firebase/firestore';
import { CreateModelRequest, ModelTableRow, createModel, getModelList } from '@app/api/model.api';

export interface ModelState {
  models: ModelTableRow[];
  unsubscribe?: Unsubscribe;
  isModelsLoading: boolean;
  isActionLoading: boolean;
}

const initialState: ModelState = {
  models: [],
  isModelsLoading: false,
  isActionLoading: false,
};

export const doGetModels = createAsyncThunk('model/getList', async (payload: void, { dispatch }) => {
  dispatch(doSetLoading(true));
  const unsubscribe = getModelList({
    observer: (snapShot) => {
      const _data: ModelTableRow[] = [];
      snapShot.forEach((doc) => {
        const _item = doc.data();
        _data.push({
          id: doc.id,
          name: _item.name,
          label: _item.label,
        });
      });
      dispatch(doUpdateModels(_data));
      dispatch(doSetLoading(false));
    },
    onError: (error) => {
      // console.log(error);
      dispatch(doSetError({ title: error.name, message: error.message }));
      dispatch(doSetLoading(false));
    },
  });
  return {
    unsubscribe,
    metadata: payload,
  };
});

export const doSetLoading = createAction<PrepareAction<boolean>>('model/setLoading', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetError = createAction<PrepareAction<{ title: string; message: string }>>(
  'model/setError',
  (error: { title: string; message: string }) => {
    return { payload: error };
  },
);

export const doUpdateModels = createAction<PrepareAction<ModelTableRow[]>>(
  'model/doUpdateList',
  (models: ModelTableRow[]) => {
    return { payload: models };
  },
);

export const doCreateModel = createAsyncThunk('model/doCreate', async (payload: CreateModelRequest) =>
  createModel(payload)
    .then(async () => {
      return true;
    })
    .catch(() => {
      return false;
    }),
);

export const couponSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getForms.fulfilled, (state, action) => {
    //   state.forms = action.payload;
    // });
    builder.addCase(doGetModels.fulfilled, (state, action) => {
      state.unsubscribe = action.payload.unsubscribe;
    });
    builder.addCase(doSetLoading, (state, action) => {
      state.isModelsLoading = action.payload;
    });
    builder.addCase(doSetError, (state, action) => {
      // console.log(action.payload);
      notificationController.error({
        message: action.payload.title,
        description: action.payload.message,
      });
    });
    builder.addCase(doUpdateModels, (state, action) => {
      state.models = action.payload;
    });
    builder.addCase(doCreateModel.pending, (state, action) => {
      state.isActionLoading = true;
    });
    builder.addCase(doCreateModel.rejected, (state, action) => {
      state.isActionLoading = false;
    });
    builder.addCase(doCreateModel.fulfilled, (state, action) => {
      state.isActionLoading = false;
    });
  },
});

export default couponSlice.reducer;
