import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Pagination, SUBMISSION_COUNT_PER_PAGE, SubmissionTableRow, getSubmissionList } from '@app/api/submission.api';
import { notificationController } from '@app/controllers/notificationController';
import { Unsubscribe } from 'firebase/firestore';
import { AnswerModel } from '@app/domain/SubmissionModel';
import { FORM_FIELD_PREFIX_KEY } from '@app/constants/config/forms';

export interface SubmissionState {
  submissions: SubmissionTableRow[];
  unsubscribe?: Unsubscribe;
  isSubmissionsLoading: boolean;
  isSubmissionsLoadMore: boolean;
  metadata: Pagination;
}

const initialState: SubmissionState = {
  submissions: [],
  isSubmissionsLoading: false,
  isSubmissionsLoadMore: false,
  metadata: {
    page: 1,
    sortBy: 'createdAt',
    order: 'asc',
  },
};

export const doGetSubmissions = createAsyncThunk(
  'submission/getList',
  async (payload: { formId: string; pagination: Pagination }, { dispatch }) => {
    if (payload.pagination.page === 1) {
      dispatch(doSetLoading(true));
    } else {
      dispatch(doSetLoadMore(true));
    }
    const unsubscribe = getSubmissionList({
      ...payload.pagination,
      formId: payload.formId,
      limit: SUBMISSION_COUNT_PER_PAGE,
      observer: (snapShot) => {
        const _data: SubmissionTableRow[] = [];
        snapShot.forEach((doc) => {
          const _item = doc.data();
          const _answers = _item.answers as AnswerModel[];
          const _submissionAnswers: { [fieldKey: string]: AnswerModel } = {};
          _answers.forEach((_value) => {
            _submissionAnswers[`${FORM_FIELD_PREFIX_KEY}_${_value.name}`] = _value;
          });
          _data.push({
            id: doc.id,
            answers: _item.answers,
            formId: _item.formId,
            createdAt: _item.createdAt,
            ..._submissionAnswers,
          });
        });
        dispatch(doUpdateSubmissions(_data));
        if (payload.pagination.page === 1) {
          dispatch(doSetLoading(false));
        } else {
          dispatch(doSetLoadMore(true));
        }
      },
      onError: (error) => {
        // console.log(error);
        dispatch(doSetError({ title: error.name, message: error.message }));
        if (payload.pagination.page === 1) {
          dispatch(doSetLoading(false));
        } else {
          dispatch(doSetLoadMore(true));
        }
      },
    });
    return {
      unsubscribe,
      metadata: payload.pagination,
    };
  },
);

export const doSetLoading = createAction<PrepareAction<boolean>>('submission/setLoading', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetLoadMore = createAction<PrepareAction<boolean>>('submission/setLoadMore', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetError = createAction<PrepareAction<{ title: string; message: string }>>(
  'submission/setError',
  (error: { title: string; message: string }) => {
    return { payload: error };
  },
);

export const doUpdateSubmissions = createAction<PrepareAction<SubmissionTableRow[]>>(
  'submission/doUpdateSubmissions',
  (submissions: SubmissionTableRow[]) => {
    return { payload: submissions };
  },
);

export const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetSubmissions.fulfilled, (state, action) => {
      state.unsubscribe = action.payload.unsubscribe;
      state.metadata = { ...state.metadata, ...action.payload.metadata };
    });
    builder.addCase(doUpdateSubmissions, (state, action) => {
      state.submissions = action.payload;
    });
    builder.addCase(doSetLoading, (state, action) => {
      state.isSubmissionsLoading = action.payload;
    });
    builder.addCase(doSetLoadMore, (state, action) => {
      state.isSubmissionsLoadMore = action.payload;
    });
    builder.addCase(doSetError, (state, action) => {
      // console.log(action.payload);
      notificationController.error({
        message: action.payload.title,
        description: action.payload.message,
      });
    });
  },
});

export default submissionSlice.reducer;
