import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteForm,
  createForm,
  updateFormThumbnail,
  getFormDetail,
  updateForm,
  DynamicFormData,
  Pagination,
  getFormList,
  FORM_COUNT_PER_PAGE,
  FormTableRow,
  getHomeFormCount,
} from '@app/api/form.api';
import { FormModel, FieldModel } from '@app/domain/FormModel';
import { notificationController } from '@app/controllers/notificationController';
import { UploadResponse } from '@app/domain/UploadResponse';
import { Unsubscribe } from 'firebase/firestore';

export interface FormState {
  forms: FormTableRow[];
  unsubscribe?: Unsubscribe;
  isFormsLoading: boolean;
  isFormsLoadMore: boolean;
  metadata: Pagination;
  formData: FormTableRow | null;
  isLoadingFormData: boolean;
  isActionLoading: boolean;
}

const initialState: FormState = {
  forms: [],
  isFormsLoading: false,
  isFormsLoadMore: false,
  metadata: {
    page: 1,
    sortBy: 'name',
    order: 'asc',
  },
  formData: null,
  isLoadingFormData: false,
  isActionLoading: false,
};

export const doGetForms = createAsyncThunk('form/getList', async (payload: Pagination, { dispatch }) => {
  if (payload.page === 1) {
    dispatch(doSetLoading(true));
  } else {
    dispatch(doSetLoadMore(true));
  }
  const unsubscribe = getFormList({
    ...payload,
    limit: FORM_COUNT_PER_PAGE,
    observer: (snapShot) => {
      const _data: FormTableRow[] = [];
      snapShot.forEach((doc) => {
        const _item = doc.data();
        _data.push({
          id: doc.id,
          order: _item.order,
          name: _item.name,
          submissions: _item.submissions,
          status: _item.status,
          pages: _item.pages,
          fields: _item.fields,
          thumbnail: _item.thumbnail,
          createdAt: _item.createdAt,
          updatedAt: _item.updatedAt,
          preText: _item.preText,
          postText: _item.postText,
          submissionMessage: _item.submissionMessage,
          submissionReceivers: _item.submissionReceivers,
          subtitle: _item.subtitle,
          buttonText: _item.buttonText,
        });
      });
      dispatch(doUpdateForms(_data));
      if (payload.page === 1) {
        dispatch(doSetLoading(false));
      } else {
        dispatch(doSetLoadMore(false));
      }
    },
    onError: (error) => {
      // console.log(error);
      dispatch(doSetError({ title: error.name, message: error.message }));
      if (payload.page === 1) {
        dispatch(doSetLoading(false));
      } else {
        dispatch(doSetLoadMore(false));
      }
    },
  });
  return {
    unsubscribe,
    metadata: payload,
  };
});

export const doSetLoading = createAction<PrepareAction<boolean>>('form/setLoading', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetLoadMore = createAction<PrepareAction<boolean>>('form/setLoadMore', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetError = createAction<PrepareAction<{ title: string; message: string }>>(
  'form/setError',
  (error: { title: string; message: string }) => {
    return { payload: error };
  },
);

export const doUpdateForms = createAction<PrepareAction<FormTableRow[]>>(
  'form/doUpdateForms',
  (forms: FormTableRow[]) => {
    return { payload: forms };
  },
);

export const doCreateForm = createAsyncThunk('form/doCreateForm', async (payload: { title: string; file?: File }) =>
  createForm({
    name: payload.title,
    order: 999,
    status: 'draft',
    fields: [],
  })
    .then(async (data) => {
      if (payload.file) {
        const _formData = new FormData();
        _formData.append('file', payload.file);
        const resUpload = await updateFormThumbnail(data.id, _formData).catch(
          () => ({ success: false } as UploadResponse),
        );
        if (!resUpload.success) {
          // notificationController.error({
          //   message: 'Upload thumbnail',
          //   description: 'Failed',
          // });
        }
      }
      return true;
    })
    .catch(() => {
      return false;
    }),
);

export const doGetFormDetail = createAsyncThunk('form/doGetDetail', async (id: string) =>
  getFormDetail({
    id,
  }).then((data) => {
    // console.log(data);
    return data;
  }),
);

export const doUpdateFormDetail = createAction<PrepareAction<FormTableRow | null>>(
  'form/doUpdateDetail',
  (payload: FormTableRow | null) => {
    return { payload };
  },
);

export const doReplaceFormDetail = createAction<PrepareAction<FormTableRow | null>>(
  'form/doReplaceFormDetail',
  (payload: FormTableRow | null) => {
    return { payload };
  },
);

export const doCreateFormField = createAsyncThunk('form/doCreateField', async (payload: FieldModel) => {
  return payload;
});

export const doUpdateFormField = createAsyncThunk(
  'form/doUpdateField',
  async (payload: { index: number; field: FieldModel }) => {
    return payload;
  },
);

export const doMoveFormField = createAsyncThunk(
  'form/doMoveField',
  async (payload: { fromIndex: number; toIndex: number }) => {
    return payload;
  },
);

export const doDeleteFormField = createAsyncThunk('form/doDeleteField', async (payload: { index: number }) => {
  return payload;
});

export const doUpdateForm = createAsyncThunk(
  'form/doUpdateForm',
  async (payload: { id: string; data: DynamicFormData; thumbnail?: File }, { dispatch }) =>
    updateForm(payload.id, payload.data)
      .then(async () => {
        if (payload.thumbnail) {
          const _formData = new FormData();
          _formData.append('file', payload.thumbnail);
          const resUpload = await updateFormThumbnail(payload.id, _formData).catch(
            () => ({ success: false } as UploadResponse),
          );
          if (!resUpload.success) {
            // notificationController.error({
            //   message: 'Upload thumbnail',
            //   description: 'Failed',
            // });
          }
          dispatch(doGetFormDetail(payload.id));
          return true;
        } else {
          dispatch(doGetFormDetail(payload.id));
          return true;
        }
      })
      .catch(() => {
        return false;
      }),
);

export const doCheckHomeForms = createAsyncThunk('form/checkHomeForms', async () => {
  try {
    const _count = await getHomeFormCount();

    if (_count > 2) {
      notificationController.warning({
        message: "Home's forms count",
        description: 'You have set up more than 2 forms on the home page',
      });
    }
  } catch (error) {
    console.log(error);
  }
});

export const doDeleteForm = createAsyncThunk('form/doDeleteForm', async (id: string) => deleteForm(id));

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getForms.fulfilled, (state, action) => {
    //   state.forms = action.payload;
    // });
    builder.addCase(doGetForms.fulfilled, (state, action) => {
      state.unsubscribe = action.payload.unsubscribe;
      state.metadata = { ...state.metadata, ...action.payload.metadata };
    });
    builder.addCase(doSetLoading, (state, action) => {
      state.isFormsLoading = action.payload;
    });
    builder.addCase(doSetLoadMore, (state, action) => {
      state.isFormsLoadMore = action.payload;
    });
    builder.addCase(doSetError, (state, action) => {
      // console.log(action.payload);
      notificationController.error({
        message: action.payload.title,
        description: action.payload.message,
      });
    });
    builder.addCase(doUpdateForms, (state, action) => {
      state.forms = action.payload;
    });
    builder.addCase(doUpdateFormDetail, (state, action) => {
      if (state.formData && action.payload) {
        state.formData = { ...state.formData, ...action.payload };
      } else {
        state.formData = action.payload;
      }
    });
    builder.addCase(doReplaceFormDetail, (state, action) => {
      state.formData = action.payload;
    });
    builder.addCase(doGetFormDetail.pending, (state, action) => {
      state.isLoadingFormData = true;
    });
    builder.addCase(doGetFormDetail.rejected, (state, action) => {
      state.isLoadingFormData = false;
    });
    builder.addCase(doGetFormDetail.fulfilled, (state, action) => {
      state.formData = action.payload;
      state.isLoadingFormData = false;
    });
    builder.addCase(doUpdateFormField.fulfilled, (state, action) => {
      const _fields = state.formData?.fields?.slice(0);
      if (_fields && state.formData?.fields) {
        _fields[action.payload.index] = action.payload.field;
        state.formData.fields = _fields;
      }
    });
    builder.addCase(doDeleteFormField.fulfilled, (state, action) => {
      const _fields = state.formData?.fields?.slice(0);
      if (_fields && state.formData?.fields) {
        _fields.splice(action.payload.index, 1);
        state.formData.fields = _fields;
      }
    });
    builder.addCase(doMoveFormField.fulfilled, (state, action) => {
      if (action.payload.toIndex < 0 || action.payload.fromIndex < 0) {
        return;
      }
      const _fields = state.formData?.fields?.slice(0);
      if (_fields && state.formData) {
        const _moveField = _fields[action.payload.fromIndex];
        _fields.splice(action.payload.fromIndex, 1);
        _fields.splice(action.payload.toIndex, 0, _moveField);
        state.formData.fields = _fields;
      }
    });
    builder.addCase(doCreateFormField.fulfilled, (state, action) => {
      if (state.formData?.fields) {
        state.formData.fields = [...state.formData.fields, action.payload];
      }
    });
    builder.addCase(doUpdateForm.pending, (state, action) => {
      state.isActionLoading = true;
    });
    builder.addCase(doUpdateForm.rejected, (state, action) => {
      state.isActionLoading = false;
    });
    builder.addCase(doUpdateForm.fulfilled, (state, action) => {
      state.isActionLoading = false;
    });
  },
});

export default formSlice.reducer;
