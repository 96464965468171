import React, { useEffect, useCallback, useState } from 'react';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from '@app/components/common/buttons/Button/Button';
import { doGetCouponDetail } from '@app/store/slices/couponSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import dayjs from 'dayjs';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { LocationModel, RedemptionModel } from '@app/domain/CouponModel';
import { TimestampModel } from '@app/domain/TimestampModel';
import * as xlsx from 'xlsx';
import { Space } from 'antd';

const RedemptionReportPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { couponId } = useParams<{ couponId?: string }>();

  const { coupon, unsubscribe, isCouponLoading } = useAppSelector((state) => state.coupon.detail);

  const { isMounted } = useMounted();

  const [isLoading, setLoading] = useState(false);

  const fetch = useCallback(
    (id: string) => {
      dispatch(doGetCouponDetail(id));
    },
    [dispatch],
  );

  useEffect(() => {
    // console.log('watch manuals', pagination.current);
    if ((!coupon || coupon.id !== couponId) && couponId) {
      fetch(couponId);
    }
    return () => {
      // console.log('unsubscribe forms');
      unsubscribe?.();
    };
  }, [isMounted]);

  const onExportPress = async () => {
    if (couponId && coupon?.redemptions) {
      setLoading(true);
      const _sheet: string[][] = [];
      // xlsx header

      _sheet.push(['Code', 'Model', 'Serial nubmer', 'City', 'Country', 'Redeemed at']);

      // xlsx body
      coupon.redemptions.forEach((redemption) => {
        const _row = [
          redemption.code,
          redemption.model ?? '',
          redemption.device,
          redemption.location?.city ?? '',
          redemption.location?.country ?? '',
          redemption.redeemedAt._seconds
            ? dayjs(redemption.redeemedAt._seconds * 1000).format('DD/MM/YYYY, HH:mm:ss')
            : '',
        ];
        _sheet.push(_row);
      });

      // save xlsx
      try {
        const wb = xlsx.utils.book_new();
        const ws = xlsx.utils.aoa_to_sheet(_sheet);
        xlsx.utils.book_append_sheet(wb, ws, coupon.name);
        xlsx.writeFile(wb, coupon.name && coupon.name !== '' ? `${coupon.name}.xlsx` : 'form_report.xlsx');
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  // const onSorterPress = (sortBy: PaginationSortBy) => {
  //   if (metadata.sortBy === sortBy) {
  //     unsubscribe?.();
  //     fetch({
  //       ...metadata,
  //       sortBy: sortBy,
  //       order: metadata.order === 'asc' ? 'desc' : 'asc',
  //     });
  //   } else {
  //     unsubscribe?.();
  //     fetch({ ...metadata, sortBy: sortBy, order: 'asc' });
  //   }
  // };

  // const renderSorterHeader = (title: string, key: PaginationSortBy) => {
  //   let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
  //   let _orderAsc: boolean | undefined = undefined;
  //   if (metadata.sortBy === key) {
  //     if (metadata.order === 'asc') {
  //       _tooltip = t('dataDisplay.pagination.clickToSortDesc');
  //       _orderAsc = true;
  //     } else {
  //       _tooltip = t('dataDisplay.pagination.clickToSortAsc');
  //       _orderAsc = false;
  //     }
  //   }
  //   return (
  //     <Tooltip placement="topLeft" title={_tooltip}>
  //       <a onClick={() => onSorterPress(key)}>
  //         {title}{' '}
  //         {_orderAsc !== undefined ? (
  //           _orderAsc === true ? (
  //             <CaretUpFilled size={14} />
  //           ) : (
  //             <CaretDownFilled size={14} />
  //           )
  //         ) : (
  //           <SwapOutlined size={14} />
  //         )}
  //       </a>
  //     </Tooltip>
  //   );
  // };

  const columns: ColumnsType<RedemptionModel> = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 200,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: 180,
    },
    {
      title: 'Serial Number / Device Id',
      dataIndex: 'device',
      key: 'device',
      width: 230,
    },
    {
      title: 'Redeemed at',
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      width: 130,
      render: (date: TimestampModel) => (
        <span>{date ? dayjs(date._seconds * 1000).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: 'City',
      dataIndex: 'location',
      key: 'location',
      render: (location?: LocationModel) => <span>{location?.city ?? ''}</span>,
    },
    {
      title: 'Country',
      dataIndex: 'location',
      key: 'location',
      render: (location?: LocationModel) => <span>{location?.country ?? ''}</span>,
    },
  ];

  return (
    <>
      <Card
        padding="0 1.875rem 1.875rem 1.875rem"
        title={
          <Space style={{ marginBottom: 20 }} size={[20, 0]}>
            <a
              onClick={() => {
                if (location.key !== 'default') {
                  navigate(-1);
                } else {
                  navigate('/coupon/list');
                }
              }}
            >
              <ArrowLeftOutlined />
            </a>
            <span>{`Redemption report: ${coupon?.name ?? ''}`}</span>
          </Space>
        }
        extra={
          <div style={{ display: 'flex' }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={onExportPress}
              disabled={isCouponLoading || isLoading}
            >
              Export
            </Button>
          </div>
        }
      >
        <Table
          // rowSelection={rowSelection}
          columns={columns}
          pagination={false}
          dataSource={coupon?.redemptions}
          loading={isCouponLoading}
          // onChange={handleTableChange}
          // scroll={{ x: 1300 }}
          bordered
          rowKey={(record: RedemptionModel, index) => `redemption_${record.code}_${index}`}
          key={'redemption_report'}
        />
      </Card>
    </>
  );
};

export default RedemptionReportPage;
