import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import UserManagerPage from '@app/pages/UserManagerPage';
import FormManagerPage from '@app/pages/FormManagerPage';
import UpdateFormPage from '@app/pages/UpdateFormPage/UpdateFormPage';
import UpdateFormMainLayout from '../layouts/main/UpdateFormMainLayout/UpdateFormMainLayout';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doGetGeneral } from '@app/store/slices/generalSlice';
import ManualManagerPage from '@app/pages/ManualManagerPage';
import FormReportPage from '@app/pages/FormReportPage';
import CouponManagerPage from '@app/pages/CouponManagerPage';
import RedemptionReportPage from '@app/pages/RedemptionReportPage';
import DashboardPage from '@app/pages/DashboardPage';

const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
// const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
// const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
// const SecuritySettings = withLoading(SecuritySettingsPage);
// const Notifications = withLoading(NotificationsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const dispatch = useAppDispatch();
  const { general, errorMsg, isLoading } = useAppSelector((state) => state.general);

  useEffect(() => {
    if (!general && !errorMsg && !isLoading) {
      dispatch(doGetGeneral());
    }
  }, [general]);

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const editFormLayout = (
    <RequireAuth>
      <UpdateFormMainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<DashboardPage />} />
          <Route element={<UserManagerPage />} />
          <Route path="user/list" element={<UserManagerPage />} />
          <Route path="form/list" element={<FormManagerPage />} />
          <Route path="manual/list" element={<ManualManagerPage />} />
          <Route path="form/report/:formId" element={<FormReportPage />} />
          <Route path="coupon/list" element={<CouponManagerPage />} />
          <Route path="coupon/report/:couponId" element={<RedemptionReportPage />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
          </Route>
        </Route>
        <Route path="/form" element={editFormLayout}>
          <Route path=":formId" element={<UpdateFormPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          {/* <Route path="sign-up" element={<SignUpPage />} /> */}
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          {/* <Route path="forgot-password" element={<ForgotPasswordPage />} /> */}
          {/* <Route path="security-code" element={<SecurityCodePage />} /> */}
          {/* <Route path="new-password" element={<NewPasswordPage />} /> */}
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
