import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Space, Image, Typography, Spin, Tooltip, Row, Col } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  MANUAL_COUNT_PER_PAGE,
  ManualSectionTableRow,
  ManualSortBy,
  ManualTableRow,
  ManualsMetadata,
  ManualsPagination,
  Pagination,
  PaginationSortBy,
  SECTION_COUNT_PER_PAGE,
} from '@app/api/manual.api';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import dayjs from 'dayjs';
import {
  CaretDownFilled,
  CaretUpFilled,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SwapOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  doDeleteManual,
  doDeleteManualSection,
  doGetBanner,
  doGetManualList,
  doGetManualSectionList,
  doRemoveManualsBySectionId,
} from '@app/store/slices/manualSlice';
import { CreateManualSectionModal } from '@app/components/manual/CreateManualSectionModal/CreateManualSectionModal';
import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { CreateManualModal } from '@app/components/manual/CreateManualModal/CreateManualModal';
import { UploadBannerModal } from '@app/components/manual/UploadBannerModal/UploadBannerModal';
import { UpdateManualSectionModal } from '@app/components/manual/UpdateManualSectionModal/UpdateManualSectionModal';
import { UpdateManualModal } from '@app/components/manual/UpdateManualModal/UpdateManualModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Timestamp } from 'firebase/firestore';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';

const ManualManagerPage: React.FC = () => {
  const general = useAppSelector((state) => state.general.general);
  const manual = useAppSelector((state) => state.manual);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isMounted } = useMounted();

  const [isCreateSectionOpen, setIsCreateSectionOpen] = useState<boolean>(false);
  const [isUpdateSectionOpen, setIsUpdateSectionOpen] = useState<boolean>(false);
  const [isCreateManualOpen, setIsCreateManualOpen] = useState<boolean>(false);
  const [isUpdateManualOpen, setIsUpdateManualOpen] = useState<boolean>(false);
  const [isUploadBannerOpen, setIsUploadBannerOpen] = useState<boolean>(false);

  const [currentSection, setCurrentSection] = useState<ManualSectionTableRow>();
  const [currentManualData, setCurrentManualData] = useState<ManualTableRow>();

  const sectionsCountRef = useRef(0);

  const fetch = useCallback(
    (payload: Pagination) => {
      dispatch(doGetManualSectionList(payload));
    },
    [dispatch],
  );

  const fetchManuals = useCallback(
    (payload: { id: string; pagination: ManualsPagination }) => {
      dispatch(doGetManualList(payload));
    },
    [dispatch],
  );

  const fetchBanner = useCallback(() => {
    dispatch(doGetBanner());
  }, [dispatch]);

  useEffect(() => {
    // console.log('watch manuals', pagination.current);
    fetchBanner();
    fetch({ ...manual.metadata });
    return () => {
      // console.log('unsubscribe forms');
      manual.unsubscribe?.();
    };
  }, [isMounted]);

  useEffect(() => {
    // console.log('watch manuals', pagination.current);
    if (manual.sections) {
      if (sectionsCountRef.current < manual.sections.length) {
        manual.sections.map((_section) => {
          const _oldManualsMetadata = manual.manuals.find((_value) => _value.id === _section.id);
          if (!_oldManualsMetadata) {
            fetchManuals({ id: _section.id, pagination: { page: 1, sortBy: 'name', order: 'asc' } });
          }
        });
      } else if (sectionsCountRef.current > manual.sections.length) {
        manual.manuals.map((_manualsMetadata) => {
          const _isDeleted = !manual.sections?.find((_value) => _value.id === _manualsMetadata.id);
          if (_isDeleted) {
            // dispatch delete manualsMetadata by section id
            dispatch(doRemoveManualsBySectionId(_manualsMetadata.id));
          }
        });
      }
    }

    sectionsCountRef.current = manual.sections?.length ?? 0;
  }, [manual.sections?.length]);

  const handleDeleteSection = (sectionId: string) => {
    dispatch(doDeleteManualSection(sectionId))
      .unwrap()
      .then((res) => {
        if (res) {
          notificationController.success({
            message: 'Delete section',
            description: 'Successfully',
          });
        } else {
          notificationController.error({
            message: 'Delete section',
            description: 'Failed',
          });
        }
        // refreshPage();
      });
  };

  const handleDeleteManual = (sectionId: string, fileId: string) => {
    dispatch(doDeleteManual({ sectionId, fileId }))
      .unwrap()
      .then((res) => {
        if (res) {
          setTimeout(() => {
            const _manualsMetadata = manual.manuals.find((_value) => _value.id === sectionId);
            if (_manualsMetadata) {
              fetchManuals({ id: sectionId, pagination: _manualsMetadata.metadata });
            }
          }, 250);
          notificationController.success({
            message: 'Delete manual',
            description: 'Successfully',
          });
        } else {
          notificationController.error({
            message: 'Delete manual',
            description: 'Failed',
          });
        }
        // refreshPage();
      });
  };

  // const handleTableChange = (pagination: Pagination) => {
  //   setPagination((_value) => ({
  //     ..._value,
  //     current: pagination.current,
  //     pageSize: pagination.pageSize,
  //   }));
  //   // setSelectedRowKeys([]);
  //   // fetch();
  // };

  const hideCreateSectionModal = () => {
    setIsCreateSectionOpen(false);
  };

  const hideUpdateSectionModal = () => {
    setIsUpdateSectionOpen(false);
  };

  const hideCreateManualModal = () => {
    setIsCreateManualOpen(false);
  };

  const hideUpdateManualModal = () => {
    setIsUpdateManualOpen(false);
  };

  const hideUploadBannerModal = () => {
    setIsUploadBannerOpen(false);
  };

  const onSorterPress = (manualsMetadata: ManualsMetadata, sortBy: ManualSortBy) => {
    if (manualsMetadata.metadata.sortBy === sortBy) {
      manual.unsubscribe?.();
      fetchManuals({
        id: manualsMetadata.id,
        pagination: {
          ...manualsMetadata.metadata,
          sortBy: sortBy,
          order: manualsMetadata.metadata.order === 'asc' ? 'desc' : 'asc',
        },
      });
    } else {
      manual.unsubscribe?.();
      fetchManuals({
        id: manualsMetadata.id,
        pagination: { ...manualsMetadata.metadata, sortBy: sortBy, order: 'asc' },
      });
    }
  };

  const renderSorterHeader = (sectionId: string, title: string, key: ManualSortBy) => {
    const _manualsMetadata = manual.manuals.find((_value) => _value.id === sectionId);
    if (!_manualsMetadata) {
      return <span>{title}</span>;
    }
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (_manualsMetadata.metadata.sortBy === key) {
      if (_manualsMetadata.metadata.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(_manualsMetadata, key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns = (sectionId: string): ColumnsType<ManualTableRow> => {
    return [
      // {
      //   title: 'ID',
      //   dataIndex: 'id',
      //   key: 'id',
      // },
      {
        title: () => renderSorterHeader(sectionId, 'Name', 'name'),
        // title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Views',
        dataIndex: 'views',
        key: 'views',
        width: 100,
      },
      {
        title: 'Downloads',
        dataIndex: 'downloads',
        key: 'downloads',
        width: 100,
      },
      {
        title: () => renderSorterHeader(sectionId, 'Date uploaded', 'updatedAt'),
        // title: 'Date uploaded',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 200,
        render: (date: Timestamp) => (
          <span>{date ? dayjs(date.seconds * 1000).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>
        ),
        // defaultSortOrder: 'descend',
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        width: 300,
        render: (id, record) => {
          return (
            <Space wrap>
              <Button
                type="link"
                onClick={() => {
                  setCurrentManualData(record);
                  setIsUpdateManualOpen(true);
                }}
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure to delete this manual?"
                okText="Yes"
                cancelText="No"
                placement="left"
                onConfirm={() => handleDeleteManual(record.sectionId, id)}
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            </Space>
          );
        },
      },
    ];
  };

  return (
    <>
      <Card
        title={t('manual.bannerForHomePage')}
        key={'manual_banner_upload'}
        padding="0 1.875rem 1.875rem 1.875rem"
        style={{ height: 'auto' }}
        loading={manual.isBannerLoading}
      >
        <Button
          style={{ marginTop: '20px', marginBottom: '20px' }}
          type="default"
          onClick={() => setIsUploadBannerOpen(true)}
          disabled={manual.isSectionLoading || manual.isSectionLoadMore || manual.isBannerLoading}
        >
          {t('common.editBanner')}
        </Button>
        <BaseForm layout="vertical">
          <Row gutter={[64, 0]}>
            <Col>
              <BaseForm.Item label="Title">{manual.banner?.title}</BaseForm.Item>
            </Col>
            <Col>
              <BaseForm.Item label="Button Text">{manual.banner?.buttonText}</BaseForm.Item>
            </Col>
          </Row>
          <BaseForm.Item label="Subtitle">{manual.banner?.subtitle}</BaseForm.Item>
          <BaseForm.Item label="Icon">
            <Image
              style={{ marginTop: '20px' }}
              preview={false}
              width={150}
              src={
                manual.banner?.thumbnail && general?.publicPath
                  ? `${general.publicPath}/${manual.banner.thumbnail}?alt=media&xplorehash=${
                      manual.banner.updatedAt ?? '_blank'
                    }`
                  : undefined
              }
            />
          </BaseForm.Item>
        </BaseForm>
      </Card>
      <TitleText>{t('manual.sectionsList')}</TitleText>
      <InfiniteScroll
        dataLength={manual.sections?.length ?? 0}
        next={() => {
          if (!manual.isSectionLoading && !manual.isSectionLoadMore) {
            manual.unsubscribe?.();
            fetch({ ...manual.metadata, page: manual.metadata.page + 1 });
          }
        }}
        hasMore={(manual.sections?.length ?? 0) >= manual.metadata.page * SECTION_COUNT_PER_PAGE}
        loader={
          <SpinnerWrapper>
            <Spin size="large" />
          </SpinnerWrapper>
        }
        scrollableTarget={'main-content'}
      >
        {manual.sections?.map((_section, index) => {
          const _manualsMetadata = manual.manuals.find((_value) => _value.id === _section.id);
          const _isLoading = manual.loadingManuals.includes(_section.id);
          return (
            <Card
              style={{ height: 'auto', marginTop: '30px' }}
              title={
                <Space size={[30, 0]}>
                  <span>{_section.name}</span>
                  <a
                    onClick={() => {
                      setCurrentSection(_section);
                      setIsUpdateSectionOpen(true);
                    }}
                  >
                    <EditOutlined />
                  </a>
                  <Popconfirm
                    title="Are you sure to delete this section?"
                    okText="Yes"
                    cancelText="No"
                    placement="top"
                    onConfirm={() => handleDeleteSection(_section.id)}
                  >
                    <a>
                      <DeleteOutlined />
                    </a>
                  </Popconfirm>
                </Space>
              }
              key={`section_${index}`}
              padding="0 1.875rem 1.875rem 1.875rem"
              extra={
                <div style={{ display: 'flex' }}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCurrentSection(_section);
                      setIsCreateManualOpen(true);
                    }}
                    disabled={_isLoading}
                  >
                    Add new item
                  </Button>
                </div>
              }
            >
              {_manualsMetadata ? (
                <>
                  <Table
                    // rowSelection={rowSelection}
                    columns={columns(_manualsMetadata.id)}
                    pagination={false}
                    dataSource={_manualsMetadata.list}
                    loading={_isLoading}
                    // onChange={handleTableChange}
                    // scroll={{ x: 1600 }}
                    bordered
                    rowKey={(record: ManualTableRow) => record.id}
                    key={`manuals_manager_${index}`}
                  />
                  {_manualsMetadata.list.length >= _manualsMetadata.metadata.page * MANUAL_COUNT_PER_PAGE ? (
                    <Button
                      style={{ marginTop: '10px' }}
                      type="dashed"
                      onClick={() => {
                        fetchManuals({
                          id: _manualsMetadata.id,
                          pagination: { ..._manualsMetadata.metadata, page: _manualsMetadata.metadata.page + 1 },
                        });
                      }}
                      loading={_isLoading}
                    >
                      {t('common.loadMore')}
                    </Button>
                  ) : null}
                </>
              ) : null}
            </Card>
          );
        })}
      </InfiniteScroll>
      <Space style={{ marginTop: '1.5rem', marginBottom: '3rem', marginLeft: '1.875rem' }}>
        {/* {(manual.sections?.length ?? 0) >= manual.metadata.page * SECTION_COUNT_PER_PAGE ? (
          <Button
            type="dashed"
            onClick={() => {
              manual.unsubscribe?.();
              fetch({ ...manual.metadata, page: manual.metadata.page + 1 });
            }}
            loading={manual.isSectionLoading}
          >
            {t('manual.loadMoreSections')}
          </Button>
        ) : null} */}
        <NewSectionButton
          type="primary"
          htmlType="button"
          icon={<PlusOutlined />}
          loading={manual.isSectionLoading || manual.isSectionLoadMore}
          onClick={() => setIsCreateSectionOpen(true)}
        >
          {t('manual.newSection')}
        </NewSectionButton>
      </Space>
      <CreateManualSectionModal
        open={isCreateSectionOpen}
        onCancel={hideCreateSectionModal}
        onFinish={() =>
          setTimeout(() => {
            hideCreateSectionModal();
          }, 250)
        }
      />
      <CreateManualModal
        sectionId={currentSection?.id}
        sectionName={currentSection?.name}
        open={isCreateManualOpen}
        onCancel={() => {
          setCurrentSection(undefined);
          hideCreateManualModal();
        }}
        onFinish={() =>
          setTimeout(() => {
            if (currentSection?.id) {
              const _manualsMetadata = manual.manuals.find((_value) => _value.id === currentSection.id);
              if (_manualsMetadata) {
                fetchManuals({ id: currentSection.id, pagination: _manualsMetadata.metadata });
              }
            }
            setCurrentSection(undefined);
            hideCreateManualModal();
          }, 250)
        }
      />
      <UpdateManualSectionModal
        data={currentSection}
        open={isUpdateSectionOpen}
        onCancel={() => {
          setCurrentSection(undefined);
          hideUpdateSectionModal();
        }}
        onFinish={() =>
          setTimeout(() => {
            setCurrentSection(undefined);
            hideUpdateSectionModal();
          }, 250)
        }
      />
      <UpdateManualModal
        data={currentManualData}
        open={isUpdateManualOpen}
        onCancel={() => {
          setCurrentManualData(undefined);
          hideUpdateManualModal();
        }}
        onFinish={() =>
          setTimeout(() => {
            if (currentManualData?.sectionId) {
              const _manualsMetadata = manual.manuals.find((_value) => _value.id === currentManualData?.sectionId);
              if (_manualsMetadata) {
                fetchManuals({ id: _manualsMetadata.id, pagination: _manualsMetadata.metadata });
              }
            }
            setCurrentManualData(undefined);
            hideUpdateManualModal();
          }, 250)
        }
      />
      <UploadBannerModal
        data={manual.banner}
        open={isUploadBannerOpen}
        onCancel={hideUploadBannerModal}
        onFinish={() =>
          setTimeout(() => {
            hideUploadBannerModal();
          }, 250)
        }
      />
    </>
  );
};

const NewSectionButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 350px;
`;

const TitleText = styled(Typography)`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.bold};
  margin-left: 1rem;
  margin-top: 1.5rem;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export default ManualManagerPage;
