import React, { useMemo } from 'react';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { AppDate, Dates } from '@app/constants/Dates';

const clearDate = Dates.getToday().hour(0).minute(0).second(0).millisecond(0);
const clearDateMs = +clearDate;

interface DatePickerProps {
  timeRange: number[];
  setTimeRange: (timeRange: number[]) => void;
  id?: string;
}

export const DateRangePicker: React.FC<DatePickerProps> = ({ timeRange, setTimeRange, id }) => {
  const timeRangePrepared = useMemo(() => timeRange.map((time) => clearDate.add(time)), [timeRange]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (timeRange: any) => {
    const timeRangeSinceTodayMs = timeRange
      .map((time: AppDate) => time.subtract(clearDateMs, 'ms'))
      .map((time: AppDate) => +time);

    setTimeRange(timeRangeSinceTodayMs);
  };

  return (
    <DayjsDatePicker.RangePicker
      id={id}
      value={[timeRangePrepared[0], timeRangePrepared[1]]}
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      onChange={onChange}
      allowClear={false}
      order={false}
    />
  );
};
