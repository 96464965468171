import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
// import { getFirebaseUser } from '@app/services/firebase.service';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  // console.log(getFirebaseUser());

  return token && token !== '' ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
