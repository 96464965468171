import { httpApi } from '@app/api/http.api';
import { CouponModel } from '@app/domain/CouponModel';
import { PaginationOrder } from '@app/domain/PaginationModel';
import { UploadResponse } from '@app/domain/UploadResponse';
import { getFirebaseFirestore } from '@app/services/firebase.service';
import {
  DocumentSnapshot,
  FirestoreError,
  QueryConstraint,
  QuerySnapshot,
  collection,
  doc,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

export const COUPON_COUNT_PER_PAGE = 10;

export type PaginationSortBy = 'createdAt' | 'name';

export interface Pagination {
  page: number;
  sortBy: PaginationSortBy;
  order: PaginationOrder;
}

export interface CouponListRequest extends Pagination {
  limit: number;
  observer?: (snapShot: QuerySnapshot) => void;
  onError?: (error: FirestoreError) => void;
}

export interface CreateCouponRequest {
  name: string;
  availableCodes: string[];
  maxCodesPerDevice: number;
  eligibleModels: string[];
  startedAt: Date;
  expiredAt: Date;
  subtitle: string;
  buttonText: string;
}

export interface UpdateCouponRequest {
  language?: string;
  name?: string;
  availableCodes?: string[];
  maxCodesPerDevice?: number;
  eligibleModels?: string[];
  startedAt?: Date;
  expiredAt?: Date;
  subtitle?: string;
  buttonText?: string;
}

export interface CouponTableRow extends CouponModel {
  id: string;
}

export interface CouponDetailRequest {
  id: string;
  observer?: (snapShot: DocumentSnapshot) => void;
  onError?: (error: FirestoreError) => void;
}

export const getCouponsCount = async () => {
  const _CouponsFCRef = collection(getFirebaseFirestore(), 'coupons');
  return (await getCountFromServer(query(_CouponsFCRef))).data().count;
};

export const getCouponList = (payload: CouponListRequest) => {
  // console.log(payload);
  const _CouponsFCRef = collection(getFirebaseFirestore(), 'coupons');
  const _queryConstraint: QueryConstraint[] = [];
  if (payload.sortBy) {
    _queryConstraint.push(orderBy(payload.sortBy, payload.order));
  }

  const q = query(_CouponsFCRef, ..._queryConstraint, limit(payload.limit * payload.page));
  return onSnapshot(
    q,
    payload.observer ??
      (() => {
        return;
      }),
    payload.onError ??
      (() => {
        return;
      }),
  );
};

export const createCoupon = async (payload: CreateCouponRequest) =>
  httpApi.post(`/coupons`, { ...payload }).then(({ data }) => data);

export const updateCoupon = async (id: string, payload: UpdateCouponRequest) =>
  httpApi.patch(`/coupons/${id}`, { ...payload }).then(({ data }) => data);

export const updateCouponThumbnail = async (id: string, payload: FormData) =>
  httpApi.patch<UploadResponse>(`/coupons/${id}/thumbnail`, payload).then(({ data }) => data);

export const updateCouponInstruction = async (id: string, payload: FormData) =>
  httpApi.patch<UploadResponse>(`/coupons/${id}/instructions`, payload).then(({ data }) => data);

export const deleteCoupon = (id: string): Promise<undefined> =>
  httpApi.delete<undefined>(`/coupons/${id}`).then(({ data }) => data);

export const getCouponDetail = (payload: CouponDetailRequest) => {
  // console.log(payload);
  const _CouponsFCRef = doc(getFirebaseFirestore(), 'coupons', payload.id);
  return onSnapshot(
    _CouponsFCRef,
    payload.observer ??
      (() => {
        return;
      }),
    payload.onError ??
      (() => {
        return;
      }),
  );
};
