import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import {
  firebaseSignOut,
  getFirebaseErrorMsg,
  sendFirebaseVerificationEmail,
  signInFirebaseWithPassword,
} from '@app/services/firebase.service';
import { FirebaseError } from 'firebase/app';
import { Button } from '@app/components/common/buttons/Button/Button';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  // const [showBtnVerify, setShowBtnVerify] = useState(false);
  // const [email, setEmail] = useState<string | null>(null);

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);
    // showBtnVerify && setShowBtnVerify(false);
    // login to firebase first
    try {
      const userCredential = await signInFirebaseWithPassword(values.email, values.password);
      const idToken = await userCredential.user.getIdToken();
      const _user = userCredential.user;
      // console.log(idToken);
      dispatch(doLogin({ email: values.email, idToken, user: _user }))
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch((error) => {
          // console.log(error);
          // if (error.message === 'User is unverify') {
          //   setShowBtnVerify(true);
          //   setEmail(values.email);
          // } else {
          //   firebaseSignOut();
          // }
          firebaseSignOut();
          setLoading(false);
        });
    } catch (error: any) {
      if (error instanceof FirebaseError) {
        console.log(error.code);
        console.log(error.message);
        const msg = getFirebaseErrorMsg(error.code);
        notificationController.error({
          message: t('auth.signIn'),
          description: msg,
        });
      } else {
        notificationController.error({ message: error.message ?? t('results.defaultError') });
      }
      setLoading(false);
    }
  };

  // const handleResendVerificationEmail = async () => {
  //   if (email) {
  //     setLoading(true);
  //     const res = await sendFirebaseVerificationEmail();
  //     if (res) {
  //       setLoading(false);
  //     }
  //   } else {
  //     notificationController.error({
  //       message: t('steps.verification'),
  //       description: t('common.resendVerifEmailFail'),
  //     });
  //     setShowBtnVerify(false);
  //     setLoading(false);
  //   }
  // };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          {/* <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link> */}
        </Auth.ActionsWrapper>
        {/* {showBtnVerify && (
          <Auth.CenterWrapper>
            <Button type="link" onClick={handleResendVerificationEmail}>
              {t('common.resendVerificationEnail')}
            </Button>
          </Auth.CenterWrapper>
        )} */}
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
