import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Space, Tooltip, Image, Spin } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FORM_COUNT_PER_PAGE, FormTableRow, Pagination, PaginationSortBy } from '@app/api/form.api';
// import { VideoModel } from '@app/domain/VideoModel';
import { doDeleteForm, doGetForms, doReplaceFormDetail } from '@app/store/slices/formSlice';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import dayjs from 'dayjs';
// import { BASE_COLORS } from '@app/styles/themes/constants';
import { CaretDownFilled, CaretUpFilled, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { CreateFormModal } from '@app/components/formManager/CreateFormModal/CreateFormModal';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Timestamp } from 'firebase/firestore';

const FormManagerPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const general = useAppSelector((state) => state.general.general);
  const { forms, unsubscribe, isFormsLoading, isFormsLoadMore, metadata } = useAppSelector((state) => state.form);

  const { isMounted } = useMounted();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);

  const dateNow = useMemo(() => Date.now(), []);

  const fetch = useCallback(
    (payload: Pagination) => {
      dispatch(doGetForms(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    // console.log('watch manuals', pagination.current);
    fetch({ ...metadata });
    return () => {
      // console.log('unsubscribe forms');
      unsubscribe?.();
    };
  }, [isMounted]);

  const handleDelete = (rowId: string) => {
    dispatch(doDeleteForm(rowId))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Delete form',
          description: 'Successfully',
        });
        // refreshPage();
      });
  };

  const hideCreateModal = () => {
    setIsCreateOpen(false);
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (metadata.sortBy === sortBy) {
      unsubscribe?.();
      fetch({
        ...metadata,
        sortBy: sortBy,
        order: metadata.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      unsubscribe?.();
      fetch({ ...metadata, sortBy: sortBy, order: 'asc' });
    }
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (metadata.sortBy === key) {
      if (metadata.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns: ColumnsType<FormTableRow> = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Icon',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 190,
      render: (thumbnail, record, index) => {
        const _url =
          general?.publicPath && thumbnail && thumbnail !== ''
            ? `${general?.publicPath}/${thumbnail}?alt=media&xplorehash=${record.updatedAt?.seconds ?? dateNow}`
            : undefined;
        return (
          <Space size="middle">
            <Image key={`form_thumbnail_${index}`} preview={false} width={150} src={_url} />
          </Space>
        );
      },
    },
    {
      title: () => renderSorterHeader('Name', 'name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: () => renderSorterHeader('Order', 'order'),
      dataIndex: 'order',
      key: 'order',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 130,
    },
    {
      title: 'Show home',
      dataIndex: 'pages',
      key: 'pages',
      width: 130,
      render: (pages?: string[]) => {
        return <span>{!pages?.includes('home') ? 'No' : 'Yes'}</span>;
      },
    },
    {
      title: 'Submissions',
      dataIndex: 'submissions',
      key: 'submissions',
      width: 100,
    },
    {
      title: () => renderSorterHeader('Created at', 'createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 130,
      render: (date: Timestamp) => <span>{date ? dayjs(date.seconds * 1000).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>,
      // defaultSortOrder: 'descend',
    },
    // {
    //   title: () => renderSorterHeader('Updated at', 'updatedAt'),
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   width: 130,
    //   render: (date: Timestamp) => <span>{date ? dayjs(date.seconds * 1000).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>,
    //   // defaultSortOrder: 'descend',
    // },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (id, record) => {
        return (
          <Space wrap>
            <Button
              type="link"
              onClick={() => {
                dispatch(doReplaceFormDetail({ ...record }));
                setTimeout(() => {
                  navigate(`/form/${id}`);
                }, 100);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this form?"
              okText="Yes"
              cancelText="No"
              placement="left"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
            <Button
              type="link"
              onClick={() => {
                dispatch(doReplaceFormDetail({ ...record }));
                navigate(`/form/report/${id}`);
              }}
            >
              View Report
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card
        padding="0 1.875rem 1.875rem 1.875rem"
        title="Form list"
        extra={
          <div style={{ display: 'flex' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsCreateOpen(true)}
              disabled={isFormsLoading}
            >
              Create new
            </Button>
          </div>
        }
      >
        <InfiniteScroll
          dataLength={forms.length}
          next={() => {
            if (!isFormsLoading && !isFormsLoadMore) {
              unsubscribe?.();
              fetch({ ...metadata, page: metadata.page + 1 });
            }
          }}
          hasMore={forms.length >= metadata.page * FORM_COUNT_PER_PAGE}
          loader={
            <SpinnerWrapper>
              <Spin size="large" />
            </SpinnerWrapper>
          }
          scrollableTarget={'main-content'}
        >
          <Table
            // rowSelection={rowSelection}
            columns={columns}
            pagination={false}
            dataSource={forms}
            loading={isFormsLoading}
            // onChange={handleTableChange}
            // scroll={{ x: 1600 }}
            bordered
            rowKey={(record: FormTableRow) => record.id}
            key={'form_manager'}
          />
        </InfiniteScroll>
        {/* {forms.length >= metadata.page * FORM_COUNT_PER_PAGE ? (
          <Space style={{ marginTop: '1.5rem', marginBottom: '3rem', marginLeft: '1.875rem' }}>
            <Button
              type="dashed"
              onClick={() => {
                unsubscribe?.();
                fetch({ ...metadata, page: metadata.page + 1 });
              }}
              loading={isFormsLoading}
            >
              {t('manual.loadMoreSections')}
            </Button>
          </Space>
        ) : null} */}
      </Card>
      <CreateFormModal
        open={isCreateOpen}
        onCancel={hideCreateModal}
        onFinish={() =>
          setTimeout(() => {
            hideCreateModal();
          }, 250)
        }
      />
    </>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export default FormManagerPage;
