import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Image, Row, Typography } from 'antd';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';

export const Btn = styled(Button)`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;
  color: var(--secondary-color);
`;

export const SectionText = styled(Typography)`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-top: 1.5rem;
`;

export const HintText = styled(Typography)`
  color: var(--subtext-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;

export const SaveButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 300px;
  margin-top: 1.5rem;
`;

export const TitleRow = styled(Row)`
  margin: 0 0.1rem 1rem 0.1rem;
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const Thumbnail = styled(Image)`
  margin-top: 1rem;
`;

export const CustomDiv = styled.div`
  margin-top: 1rem;
`;

export const ShowHomeHintWrapper = styled.div`
  max-width: 400px;
`;
