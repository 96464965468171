import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doCreateModel } from '@app/store/slices/modelSlice';

interface CreateDeviceModelModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface NewFormData {
  label: string;
}

export const CreateDeviceModelModal: React.FC<CreateDeviceModelModalProps> = ({ open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { models, isModelsLoading, isActionLoading } = useAppSelector((state) => state.model);

  const [isLoading, setLoading] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: NewFormData) => {
    // console.log(values);
    setLoading(true);
    dispatch(doCreateModel({ label: values.label }))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Create new model',
            description: 'Successfully',
          });
          onFinish();
        } else {
          notificationController.error({
            message: 'Create new model',
            description: 'Failed',
          });
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Create Model"
      open={open}
      onOk={onOk}
      onCancel={onFormCancel}
      confirmLoading={isModelsLoading || isActionLoading || isLoading}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,.75)' }}
      zIndex={999999}
    >
      <BaseForm form={form} layout="vertical" name="CreateModel" onFinish={handleSubmit}>
        <BaseForm.Item
          name="label"
          label="Name"
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              validator: async (_, label: string) => {
                if (!label || models.map((_value) => _value.name).includes(label.toLowerCase())) {
                  return Promise.reject(new Error(t('common.modelExist')));
                }
              },
            },
          ]}
        >
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
