import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { initValues as loginInitVal } from '@app/components/auth/LoginForm/LoginForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { Dates } from '@app/constants/Dates';
import { doLogin } from '@app/store/slices/authSlice';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './LockForm.styles';
import {
  firebaseSignOut,
  getFirebaseErrorMsg,
  sendFirebaseVerificationEmail,
  signInFirebaseWithPassword,
} from '@app/services/firebase.service';
import { FirebaseError } from 'firebase/app';
import { Button } from '@app/components/common/buttons/Button/Button';
import defaultAvatar from '@app/assets/images/default-avatar.jpg';

interface LockFormData {
  password: string;
}

const initValues = {
  password: loginInitVal.password,
};

export const LockForm: React.FC = () => {
  const navigate = useNavigate();
  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const user = useAppSelector((state) => state.user.user);
  const fullName = user?.displayName ?? '';

  const currentDateInUTC = dateState.toUTCString();
  const currentTime = Dates.format(currentDateInUTC, 'h:mm A');
  const currentDate = Dates.format(currentDateInUTC, 'dddd, MMMM D, YYYY');

  // const [showBtnVerify, setShowBtnVerify] = useState(false);
  // const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => setDateState(new Date()), 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async ({ password }: LockFormData) => {
    setLoading(true);
    // login to firebase first
    try {
      const userCredential = await signInFirebaseWithPassword(user?.email || '', password);
      const idToken = await userCredential.user.getIdToken();
      const _user = userCredential.user;
      dispatch(doLogin({ email: user?.email || '', idToken, user: _user }))
        .unwrap()
        .then(() => navigate(-1))
        .catch((error: any) => {
          console.log(error);
          // if (error.message === 'User is unverify') {
          //   setShowBtnVerify(true);
          //   setEmail(user?.email ?? null);
          // } else {
          //   firebaseSignOut();
          // }
          firebaseSignOut();
          setLoading(false);
        });
    } catch (error: any) {
      if (error instanceof FirebaseError) {
        console.log(error.code);
        console.log(error.message);
        const msg = getFirebaseErrorMsg(error.code);
        notificationController.error({
          message: t('auth.signIn'),
          description: msg,
        });
      } else {
        notificationController.error({ message: error.message ?? t('results.defaultError') });
      }
      setLoading(false);
    }
  };

  // const handleResendVerificationEmail = async () => {
  //   if (email) {
  //     setLoading(true);
  //     const res = await sendFirebaseVerificationEmail();
  //     if (res) {
  //       setLoading(false);
  //     }
  //   } else {
  //     notificationController.error({
  //       message: t('steps.verification'),
  //       description: t('common.resendVerifEmailFail'),
  //     });
  //     setShowBtnVerify(false);
  //     setLoading(false);
  //   }
  // };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.ContentWrapper>
          <S.Time>{currentTime}</S.Time>
          <S.Date>{currentDate}</S.Date>
          <S.AvatarCircle>
            <Avatar src={defaultAvatar} alt="user avatar" size={mobileOnly ? 59 : 77} />
          </S.AvatarCircle>
          <S.Name>{fullName}</S.Name>
        </S.ContentWrapper>
        <S.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </S.FormItem>
        {/* {showBtnVerify && (
          <Auth.CenterWrapper>
            <Button type="link" onClick={handleResendVerificationEmail}>
              {t('common.resendVerificationEnail')}
            </Button>
          </Auth.CenterWrapper>
        )} */}
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
