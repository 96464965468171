import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Avatar, Space } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { UserTableRow, getUserList } from '@app/api/users.api';
import defaultAvatar from 'assets/images/default-avatar.jpg';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { doDeleteUser } from '@app/store/slices/userSlice';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { UpdateUserFormModal } from '@app/components/user/UpdateUserForm/UpdateUserForm';
import { PlusOutlined } from '@ant-design/icons';
import { AddUserFormModal } from '@app/components/user/AddUserForm/AddUserForm';
import { UserDetailModal } from '@app/components/user/DetailUser/DetailUser';
import { useMounted } from '@app/hooks/useMounted';
import { Pagination } from '@app/api/users.api';
import { Dates } from '@app/constants/Dates';
// import { TableRowSelection } from 'antd/lib/table/interface';

export enum StatusEnum {
  'active' = 1,
  'inactive' = 2,
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
};

const UserManagerPage: React.FC = () => {
  const isSuperAdmin = useAppSelector((state) => state.user.isSuperAdmin);
  const [isLoading, setLoading] = useState(false);
  // const [pagination, setPagination] = useState<Pagination>(initialPagination);
  // const [sort, setSort] = useState<{
  //   sortBy: PaginationSortBy;
  //   order: PaginationOrder;
  // }>({
  //   sortBy: 'createdAt',
  //   order: 'desc',
  // });
  // const prevPageRef = useRef<number>(1);
  // const prevDocRef = useRef<DocumentSnapshot[]>([]);
  // const snapShotRef = useRef<QuerySnapshot>();
  const [tableData, setTableData] = useState<UserTableRow[]>([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const [isLoading, setLoading] = useState(false);
  // const [doReload, setDoReload] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<UserTableRow | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { isMounted } = useMounted();
  // const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  // const { pagination, setPagination } = useState<Pagination>({ ...initialPagination });

  const fetch = useCallback(async () => {
    setLoading(true);
    return await getUserList().then((res) => {
      // console.log(res);
      const data: UserTableRow[] = res.data;
      setTableData(data);
      setLoading(false);
    });
  }, [isMounted]);

  // const fetch = useCallback(
  //   (
  //     pagination: Pagination,
  //     sort: { sortBy: PaginationSortBy; order: PaginationOrder },
  //     isNextPage: boolean,
  //     lastVisible?: DocumentSnapshot,
  //   ) => {
  //     setLoading(true);
  //     return getUserList({
  //       page: pagination.current,
  //       limit: pagination.pageSize,
  //       sortBy: sort.sortBy,
  //       order: sort.order,
  //       lastVisible: lastVisible,
  //       observer: async (snapShot) => {
  //         snapShotRef.current = snapShot;
  //         prevPageRef.current = pagination.current ?? 1;
  //         if (isNextPage) {
  //           lastVisible && prevDocRef.current.push(lastVisible);
  //         } else {
  //           prevDocRef.current.pop();
  //         }
  //         // TODO: check number of items when deleted some things to decrease page when out of item
  //         const _total = await getUsersCount();
  //         const _data: UserTableRow[] = [];
  //         snapShot.forEach((doc) => {
  //           const _item = doc.data();
  //           _data.push({
  //             id: doc.id,
  //             email: _item.email,
  //             firstName: _item.firstName,
  //             lastName: _item.lastName,
  //             createdAt: _item.createdAt,
  //           });
  //         });
  //         setPagination((_value) => ({
  //           ..._value,
  //           total: _total,
  //         }));
  //         setTableData(_data);
  //         setLoading(false);
  //       },
  //     });
  //   },
  //   [isMounted],
  // );

  // const fetchUsers = useCallback(
  //   (doReload: boolean) => {
  //     if (doReload) {
  //       getUserList({ page: 1, limit: 10 })
  //         .then((res) => {
  //           setTableData(res.data);
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //         });
  //     }
  //   },
  //   [doReload],
  // );

  useEffect(() => {
    fetch();
  }, [fetch]);

  // useEffect(() => {
  //   console.log('watch users', pagination.current);
  //   const _isNextPage = prevPageRef.current < (pagination.current ?? 1);
  //   const unsubscribe = fetch(
  //     pagination,
  //     sort,
  //     _isNextPage,
  //     _isNextPage
  //       ? pagination.current !== 1 && snapShotRef.current?.docs && snapShotRef.current.docs.length > 0
  //         ? snapShotRef.current?.docs[snapShotRef.current.docs.length - 1]
  //         : undefined
  //       : prevDocRef.current && prevDocRef.current.length > 0
  //       ? prevDocRef.current[prevDocRef.current.length - 1]
  //       : undefined,
  //   );
  //   return () => {
  //     console.log('unsubscribe users');
  //     unsubscribe();
  //   };
  // }, [pagination.current, pagination.pageSize, sort, fetch]);

  const handleDelete = (rowId: string) => {
    dispatch(doDeleteUser(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Remove user',
          description: 'Successfully',
        });
        // setSelectedRowKeys([]);
        fetch();
      });
  };

  // const handleBulkDelete = () => {
  //   if (selectedRowKeys.length <= 0) {
  //     return;
  //   }
  //   dispatch(doBulkDeleteUser(selectedRowKeys))
  //     .unwrap()
  //     .then((res) => {
  //       notificationController.success({
  //         message: 'Remove users',
  //         description: 'Successfully',
  //       });
  //       setSelectedRowKeys([]);
  //       fetch();
  //     });
  // };

  const handleEdit = (record: UserTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsEditOpen(true);
    }, 250);
  };
  const handleOpenDetail = (record: UserTableRow) => {
    setItemSelected(record);
    setTimeout(() => {
      setIsDetailOpen(true);
    }, 250);
  };
  const openNewUserModal = () => {
    setIsCreateOpen(true);
  };
  const hideNewUserModal = () => {
    setIsCreateOpen(false);
  };
  // const handleTableChange = (pagination: Pagination) => {
  //   // setPagination((_value) => ({
  //   //   ..._value,
  //   //   current: pagination.current,
  //   //   pageSize: pagination.pageSize,
  //   // }));
  //   // setSelectedRowKeys([]);
  //   fetch();
  // };

  // const onSorterPress = (sortBy: PaginationSortBy) => {
  //   if (sort.sortBy === sortBy) {
  //     setSort({ sortBy: sortBy, order: sort.order === 'asc' ? 'desc' : 'asc' });
  //   } else {
  //     setSort({ sortBy: sortBy, order: 'asc' });
  //   }
  // };

  // const renderSorterHeader = (title: string, key: PaginationSortBy) => {
  //   let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
  //   let _orderAsc: boolean | undefined = undefined;
  //   if (sort.sortBy === key) {
  //     if (sort.order === 'asc') {
  //       _tooltip = t('dataDisplay.pagination.clickToSortDesc');
  //       _orderAsc = true;
  //     } else {
  //       _tooltip = t('dataDisplay.pagination.clickToSortAsc');
  //       _orderAsc = false;
  //     }
  //   }
  //   return (
  //     <Tooltip placement="topLeft" title={_tooltip}>
  //       <a onClick={() => onSorterPress(key)}>
  //         {title}{' '}
  //         {_orderAsc !== undefined ? (
  //           _orderAsc === true ? (
  //             <CaretUpFilled size={14} />
  //           ) : (
  //             <CaretDownFilled size={14} />
  //           )
  //         ) : (
  //           <SwapOutlined size={14} />
  //         )}
  //       </a>
  //     </Tooltip>
  //   );
  // };

  const columns: ColumnsType<UserTableRow> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'displayName',
        key: 'displayName',
        render: (_, record) => (
          <Space size="middle">
            <Avatar shape="circle" src={defaultAvatar} />
            <a onClick={() => handleOpenDetail(record)}>{record.displayName}</a>
          </Space>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        // title: () => renderSorterHeader('Created at', 'createdAt'),
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date?: string) => <span>{date ? Dates.getDate(date).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>,
        // sorter: (a: UserTableRow, b: UserTableRow) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
        // defaultSortOrder: 'descend',
      },
      ...(isSuperAdmin
        ? [
            {
              title: 'Action',
              dataIndex: 'uid',
              key: 'uid',
              render: (uid: string) => {
                return (
                  <Space>
                    {/* <Button type="link" onClick={() => handleEdit(record)}>
              Edit
            </Button> */}
                    <Popconfirm
                      title="Are you sure to delete this user?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleDelete(uid)}
                    >
                      <Button type="link">Delete</Button>
                    </Popconfirm>
                  </Space>
                );
              },
            },
          ]
        : []),
    ],
    [isSuperAdmin],
  );

  // const onSelectedRowKeysChange = (selectedRowKeys: React.Key[]) => {
  //   setSelectedRowKeys(selectedRowKeys as number[]);
  // };

  // const rowSelection: TableRowSelection<UserTableRow> = {
  //   selectedRowKeys,
  //   onChange: onSelectedRowKeysChange,
  // };

  return (
    <>
      <Card
        padding="0 1.875rem 1.875rem 1.875rem"
        title="User list"
        extra={
          <div style={{ display: 'flex' }}>
            {isSuperAdmin && (
              <Button type="primary" icon={<PlusOutlined />} onClick={openNewUserModal}>
                Create new
              </Button>
            )}
            {/* <Popconfirm
              title="Are you sure to delete selected users?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleBulkDelete}
            >
              <Button style={{ marginLeft: 15 }} type="ghost" disabled={selectedRowKeys.length <= 0}>
                Delete
              </Button>
            </Popconfirm> */}
          </div>
        }
      >
        <Table
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          loading={isLoading}
          pagination={false}
          // pagination={{
          //   ...pagination,
          //   itemRender: (page, type, element) => {
          //     if (type === 'page') {
          //       return <span>{page}</span>;
          //     } else if (type === 'jump-next' || type === 'jump-prev') {
          //       return <span></span>;
          //     }
          //     return element;
          //   },
          //   style: { alignItems: 'center' },
          // }}
          // onChange={handleTableChange}
          // scroll={{ x: 1400 }}
          bordered
          rowKey={(record) => record.uid}
        />
      </Card>
      <BaseButtonsForm.Provider
        onFormFinish={(name) => {
          if (name === 'updateUserForm') {
            setIsEditOpen(false);
          } else if (name === 'addUserForm') {
            setIsCreateOpen(false);
          }
        }}
      >
        {itemSelected && (
          <UserDetailModal
            open={isDetailOpen}
            onCancel={() => {
              setItemSelected(null);
              setIsDetailOpen(false);
            }}
            data={itemSelected}
          />
        )}
        <AddUserFormModal
          open={isCreateOpen}
          onCancel={hideNewUserModal}
          onFinish={() => {
            setTimeout(() => {
              fetch();
            }, 250);
          }}
        />
        {itemSelected && (
          <UpdateUserFormModal
            open={isEditOpen}
            onCancel={() => {
              setItemSelected(null);
              setIsEditOpen(false);
            }}
            onFinish={() => {
              setTimeout(() => {
                setItemSelected(null);
                setIsEditOpen(false);
                fetch();
              }, 250);
            }}
            data={itemSelected}
          />
        )}
      </BaseButtonsForm.Provider>
    </>
  );
};

export default UserManagerPage;
