import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Pagination,
  COUPON_COUNT_PER_PAGE,
  CouponTableRow,
  getCouponList,
  createCoupon,
  CreateCouponRequest,
  updateCouponThumbnail,
  updateCouponInstruction,
  updateCoupon,
  deleteCoupon,
  UpdateCouponRequest,
  getCouponDetail,
} from '@app/api/coupon.api';
import { notificationController } from '@app/controllers/notificationController';
import { UploadResponse } from '@app/domain/UploadResponse';
import { Unsubscribe } from 'firebase/firestore';

export interface CouponState {
  coupons: CouponTableRow[];
  unsubscribe?: Unsubscribe;
  isCouponsLoading: boolean;
  isCouponsLoadMore: boolean;
  metadata: Pagination;
  isActionLoading: boolean;
  detail: {
    coupon: CouponTableRow | null;
    unsubscribe?: Unsubscribe;
    isCouponLoading: boolean;
  };
}

const initialState: CouponState = {
  coupons: [],
  isCouponsLoading: false,
  isCouponsLoadMore: false,
  metadata: {
    page: 1,
    sortBy: 'name',
    order: 'asc',
  },
  isActionLoading: false,
  detail: {
    coupon: null,
    isCouponLoading: false,
  },
};

export const doGetCoupons = createAsyncThunk('coupon/getList', async (payload: Pagination, { dispatch }) => {
  if (payload.page === 1) {
    dispatch(doSetLoading(true));
  } else {
    dispatch(doSetLoadMore(true));
  }
  const unsubscribe = getCouponList({
    ...payload,
    limit: COUPON_COUNT_PER_PAGE,
    observer: (snapShot) => {
      const _data: CouponTableRow[] = [];
      snapShot.forEach((doc) => {
        const _item = doc.data();
        _data.push({
          id: doc.id,
          name: _item.name,
          startedAt: _item.startedAt,
          expiredAt: _item.expiredAt,
          eligibleModels: _item.eligibleModels,
          availableCodes: _item.availableCodes,
          maxCodesPerDevice: _item.maxCodesPerDevice,
          thumbnail: _item.thumbnail,
          instructions: _item.instructions,
          createdAt: _item.createdAt,
          updatedAt: _item.updatedAt,
          redemptions: _item.redemptions,
          subtitle: _item.subtitle,
          buttonText: _item.buttonText,
        });
      });
      dispatch(doUpdateCoupons(_data));
      if (payload.page === 1) {
        dispatch(doSetLoading(false));
      } else {
        dispatch(doSetLoadMore(false));
      }
    },
    onError: (error) => {
      // console.log(error);
      dispatch(doSetError({ title: error.name, message: error.message }));
      if (payload.page === 1) {
        dispatch(doSetLoading(false));
      } else {
        dispatch(doSetLoadMore(false));
      }
    },
  });
  return {
    unsubscribe,
    metadata: payload,
  };
});

export const doSetLoading = createAction<PrepareAction<boolean>>('coupon/setLoading', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetLoadMore = createAction<PrepareAction<boolean>>('coupon/setLoadMore', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetError = createAction<PrepareAction<{ title: string; message: string }>>(
  'coupon/setError',
  (error: { title: string; message: string }) => {
    return { payload: error };
  },
);

export const doUpdateCoupons = createAction<PrepareAction<CouponTableRow[]>>(
  'coupon/doUpdateList',
  (coupons: CouponTableRow[]) => {
    return { payload: coupons };
  },
);

export const doCreateCoupon = createAsyncThunk(
  'coupon/doCreate',
  async (payload: { data: CreateCouponRequest; thumbnail?: File; instruction?: File }) =>
    createCoupon(payload.data)
      .then(async (data) => {
        if (payload.thumbnail) {
          const _formData = new FormData();
          _formData.append('file', payload.thumbnail);
          const resUpload = await updateCouponThumbnail(data.id, _formData).catch(
            () => ({ success: false } as UploadResponse),
          );
          if (!resUpload.success) {
            // notificationController.error({
            //   message: 'Upload thumbnail',
            //   description: 'Failed',
            // });
          }
        }
        if (payload.instruction) {
          const _formData = new FormData();
          _formData.append('file', payload.instruction);
          const resUpload = await updateCouponInstruction(data.id, _formData).catch(
            () => ({ success: false } as UploadResponse),
          );
          if (!resUpload.success) {
            // notificationController.error({
            //   message: 'Upload thumbnail',
            //   description: 'Failed',
            // });
          }
        }
        return true;
      })
      .catch(() => {
        return false;
      }),
);

export const doUpdateCoupon = createAsyncThunk(
  'coupon/doUpdate',
  async (payload: { id: string; data: UpdateCouponRequest; thumbnail?: File; instruction?: File }) =>
    updateCoupon(payload.id, payload.data)
      .then(async () => {
        if (payload.thumbnail) {
          const _formData = new FormData();
          _formData.append('file', payload.thumbnail);
          const resUpload = await updateCouponThumbnail(payload.id, _formData).catch(
            () => ({ success: false } as UploadResponse),
          );
          if (!resUpload.success) {
            // notificationController.error({
            //   message: 'Upload thumbnail',
            //   description: 'Failed',
            // });
          }
        }
        if (payload.instruction) {
          const _formData = new FormData();
          if (payload.data.language) _formData.append('lang', payload.data.language);
          _formData.append('file', payload.instruction);
          const resUpload = await updateCouponInstruction(payload.id, _formData).catch(
            () => ({ success: false } as UploadResponse),
          );
          if (!resUpload.success) {
            // notificationController.error({
            //   message: 'Upload thumbnail',
            //   description: 'Failed',
            // });
          }
        }
        return true;
      })
      .catch(() => {
        return false;
      }),
);

export const doDeleteCoupon = createAsyncThunk('coupon/doDelete', async (id: string) => deleteCoupon(id));

export const doGetCouponDetail = createAsyncThunk('coupon/doGetDetail', async (id: string, { dispatch }) => {
  dispatch(doSetDetailLoading(true));
  const unsubscribe = getCouponDetail({
    id,
    observer: (snapShot) => {
      if (!snapShot.exists()) {
        notificationController.error({
          message: 'Redemption Report',
          description: 'Coupon not exist',
        });
      }
      const _docData = snapShot.data();
      const _data: CouponTableRow | null = _docData
        ? {
            id: snapShot.id,
            name: _docData.name,
            startedAt: _docData.startedAt,
            expiredAt: _docData.expiredAt,
            eligibleModels: _docData.eligibleModels,
            availableCodes: _docData.availableCodes,
            maxCodesPerDevice: _docData.maxCodesPerDevice,
            thumbnail: _docData.thumbnail,
            instructions: _docData.instructions,
            createdAt: _docData.createdAt,
            updatedAt: _docData.updatedAt,
            redemptions: _docData.redemptions,
            subtitle: _docData.subtitle,
            buttonText: _docData.buttonText,
          }
        : null;
      dispatch(doUpdateCouponDetail(_data));
      dispatch(doSetDetailLoading(false));
    },
    onError: (error) => {
      // console.log(error);
      dispatch(doSetError({ title: error.name, message: error.message }));
      dispatch(doSetDetailLoading(false));
    },
  });

  return {
    unsubscribe,
  };
});

export const doUpdateCouponDetail = createAction<PrepareAction<CouponTableRow | null>>(
  'coupon/doUpdateDetail',
  (coupon: CouponTableRow | null) => {
    return { payload: coupon };
  },
);

export const doSetDetailLoading = createAction<PrepareAction<boolean>>(
  'coupon/doSetDetailLoading',
  (isLoading: boolean) => {
    return { payload: isLoading };
  },
);

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getForms.fulfilled, (state, action) => {
    //   state.forms = action.payload;
    // });
    builder.addCase(doGetCoupons.fulfilled, (state, action) => {
      state.unsubscribe = action.payload.unsubscribe;
      state.metadata = { ...state.metadata, ...action.payload.metadata };
    });
    builder.addCase(doSetLoading, (state, action) => {
      state.isCouponsLoading = action.payload;
    });
    builder.addCase(doSetLoadMore, (state, action) => {
      state.isCouponsLoadMore = action.payload;
    });
    builder.addCase(doSetError, (state, action) => {
      // console.log(action.payload);
      notificationController.error({
        message: action.payload.title,
        description: action.payload.message,
      });
    });
    builder.addCase(doUpdateCoupons, (state, action) => {
      state.coupons = action.payload;
    });
    builder.addCase(doUpdateCoupon.pending, (state, action) => {
      state.isActionLoading = true;
    });
    builder.addCase(doUpdateCoupon.rejected, (state, action) => {
      state.isActionLoading = false;
    });
    builder.addCase(doUpdateCoupon.fulfilled, (state, action) => {
      state.isActionLoading = false;
    });
    builder.addCase(doGetCouponDetail.fulfilled, (state, action) => {
      state.detail.unsubscribe = action.payload.unsubscribe;
    });
    builder.addCase(doUpdateCouponDetail, (state, action) => {
      state.detail.coupon = action.payload;
    });
    builder.addCase(doSetDetailLoading, (state, action) => {
      state.detail.isCouponLoading = action.payload;
    });
  },
});

export default couponSlice.reducer;
