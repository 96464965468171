import React, { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { UpdateUserData, UserTableRow } from '@app/api/users.api';
import { doUpdateUser } from '@app/store/slices/userSlice';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';

interface UpdateUserFormModalProps {
  data: UserTableRow;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}

const initialInfoValues = {
  id: -1,
};

export const UpdateUserFormModal: React.FC<UpdateUserFormModalProps> = ({ open, onCancel, onFinish, data }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = () => {
    form.submit();
  };

  const userFormValues = useMemo(
    () =>
      data
        ? {
            ...data,
          }
        : initialInfoValues,
    [data],
  );

  const handleSubmit = (values: UpdateUserData) => {
    setLoading(true);
    dispatch(doUpdateUser({ ...values, uid: data.uid }))
      .unwrap()
      .then(() => {
        setLoading(false);
        notificationController.success({
          message: 'Update user',
          description: 'Successfully',
        });
        onFinish();
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Update user"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <BaseForm
        initialValues={userFormValues}
        form={form}
        layout="vertical"
        name="updateUserForm"
        onFinish={handleSubmit}
      >
        <BaseForm.Item
          name="displayName"
          label="Display name"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[
            {
              pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w~`@$!%*#?&^\(\)\-+=|\\:;"'<>,.\{\}\[\]]{7,}$/),
              message: t('common.passwordInvalid'),
            },
            { required: false, message: t('common.requiredField') },
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        {/* <BaseForm.Item name="status" label="Status" rules={[{ required: false, message: t('common.requiredField') }]}>
          <Switch checked={status} onChange={() => setStatus(!status)} />
        </BaseForm.Item> */}
      </BaseForm>
    </Modal>
  );
};
