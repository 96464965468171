import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UploadOutlined } from '@ant-design/icons';
import { resizeThumbnail } from '@app/utils/utils';
import { doUpdateBanner } from '@app/store/slices/manualSlice';
import * as S from './UploadBannerModal.styles';
import { BannerModel } from '@app/domain/bannerModel';
import { UpdateBannerRequest } from '@app/api/manual.api';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { getTranslations } from '@app/api/general.api';
import { Languages } from '@app/components/Languages';

interface UploadBannerModalProps {
  data?: BannerModel;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface UpdateBannerData extends UpdateBannerRequest {
  files: File[];
}

export const UploadBannerModal: React.FC<UploadBannerModalProps> = ({ data, open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const general = useAppSelector((state) => state.general.general);

  const [isLoading, setLoading] = useState(false);

  const [banner, setBanner] = useState<string | undefined>(
    general?.publicPath && data?.thumbnail && data.thumbnail !== ''
      ? `${general?.publicPath}/${data.thumbnail}?alt=media&xplorehash=${data.updatedAt ?? Date.now()}`
      : undefined,
  );
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en');

  const dateNow = useMemo(() => Date.now(), [open]);

  useResetFormOnCloseModal({
    form,
    open,
  });

  useEffect(() => {
    if (!open) {
      setBanner(undefined);
      setLanguage('en');
    }
  }, [open]);

  useEffect(() => {
    if (open && data) {
      form.setFieldsValue({ ...data });
      fetchTranslations();
    }
  }, [open, data]);

  useEffect(() => {
    if (open && general && data?.thumbnail && data.thumbnail !== '') {
      setBanner(
        general?.publicPath
          ? `${general?.publicPath}/${data.thumbnail}?alt=media&xplorehash=${data.updatedAt ?? dateNow}`
          : undefined,
      );
    }
  }, [open, general, data?.thumbnail]);

  useEffect(() => {
    const values = translations?.[language] ?? {};
    form.resetFields();
    form.setFieldsValue({ ...values });
    initThumbnail();
  }, [language]);

  const initThumbnail = () => {
    const values = translations?.[language] ?? {};
    const thumbnail = values.thumbnail;
    setBanner(
      general?.publicPath && thumbnail
        ? `${general?.publicPath}/${thumbnail}?alt=media&xplorehash=${dateNow}`
        : undefined,
    );
  };

  const onOk = async () => {
    form.submit();
  };

  const fetchTranslations = async () => {
    if (data && data.id) {
      const translations = await getTranslations('/banners', data.id);
      translations['en'] = { ...data };
      setTranslations(translations);
    }
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: UpdateBannerData) => {
    const _bannerFile: File | any = values.files[0];
    setLoading(true);

    const _data: UpdateBannerRequest = {
      language,
      title: values.title,
      subtitle: values.subtitle,
      buttonText: values.buttonText,
    };

    dispatch(doUpdateBanner({ data: _data, thumbnail: _bannerFile?.originFileObj ?? _bannerFile }))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Upload manual banner',
            description: 'Successfully',
          });
          onFinish();
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    // const fmData = new FormData();
    let _thumbnail = file;
    try {
      _thumbnail = await resizeThumbnail(file, 104, 104);
    } catch (error) {
      console.log('[resizeThumbnail] error:', error);
      // form.setFieldsValue({ files: [file] });
    }

    form.setFieldsValue({ files: [_thumbnail] });

    setBanner(URL.createObjectURL(file));

    // console.log((fmData.get('file') as File).name);

    try {
      // const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      onSuccess('Ok');
      // form.setFieldsValue({ files: [fmData.get('file')] });
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <Modal title="Upload new banner" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <Languages selected={language} onChange={setLanguage} />
      <BaseForm form={form} layout="vertical" name="UploadBannerForm" onFinish={handleSubmit}>
        <BaseForm.Item name="title" label="Title" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="subtitle"
          label="Subtitle"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={2} />
        </BaseForm.Item>
        <BaseForm.Item
          name="buttonText"
          label="Button Text"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="files"
          label="Upload Banner Icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
        >
          <Upload
            accept="image/*"
            maxCount={1}
            customRequest={dummyRequest}
            listType="picture"
            onRemove={initThumbnail}
          >
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
            <S.HintText>{t('manual.uploadBannerHint')}</S.HintText>
            <Image style={{ marginTop: '20px' }} preview={false} width={150} src={banner} />
          </Upload>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
