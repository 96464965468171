import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getManualSectionList,
  ManualSectionTableRow,
  updateManualSection,
  createManualSection,
  deleteManualSection,
  Pagination,
  SECTION_COUNT_PER_PAGE,
  ManualsMetadata,
  MANUAL_COUNT_PER_PAGE,
  getManualList,
  ManualTableRow,
  uploadBanner,
  createManual,
  updateManual,
  deleteManual,
  ManualsPagination,
  getBanner,
  UpdateBannerRequest,
  updateBanner,
} from '@app/api/manual.api';
import { Unsubscribe } from 'firebase/firestore';
import { ManualSectionModel } from '@app/domain/ManualSectionModel';
import { notificationController } from '@app/controllers/notificationController';
import { BannerModel } from '@app/domain/bannerModel';
import { UploadResponse } from '@app/domain/UploadResponse';

export interface ManualState {
  sections?: ManualSectionTableRow[];
  unsubscribe?: Unsubscribe;
  isSectionLoading: boolean;
  isSectionLoadMore: boolean;
  metadata: Pagination;
  manuals: ManualsMetadata[];
  loadingManuals: string[];
  banner?: BannerModel;
  isBannerLoading: boolean;
}

const initialState: ManualState = {
  isSectionLoading: false,
  isSectionLoadMore: false,
  metadata: {
    page: 1,
    sortBy: 'order',
    order: 'asc',
  },
  manuals: [],
  loadingManuals: [],
  isBannerLoading: false,
};

export const doGetManualSectionList = createAsyncThunk(
  'manual/sections/list',
  async (payload: Pagination, { dispatch }) => {
    if (payload.page === 1) {
      dispatch(doSetLoading(true));
    } else {
      dispatch(doSetLoadMore(true));
    }
    const unsubscribe = getManualSectionList({
      ...payload,
      limit: SECTION_COUNT_PER_PAGE,
      observer: (snapShot) => {
        const _data: ManualSectionTableRow[] = [];
        snapShot.forEach((doc) => {
          const _item = doc.data();
          _data.push({
            id: doc.id,
            order: _item.order,
            name: _item.name,
          });
        });
        dispatch(doUpdateSections(_data));
        if (payload.page === 1) {
          dispatch(doSetLoading(false));
        } else {
          dispatch(doSetLoadMore(false));
        }
      },
      onError: (error) => {
        // console.log(error);
        dispatch(doSetError({ title: error.name, message: error.message }));
        if (payload.page === 1) {
          dispatch(doSetLoading(false));
        } else {
          dispatch(doSetLoadMore(false));
        }
      },
    });
    return {
      unsubscribe,
      metadata: payload,
    };
  },
);

export const doUpdateSections = createAction<PrepareAction<ManualSectionTableRow[]>>(
  'manual/sections/update',
  (sections: ManualSectionTableRow[]) => {
    return { payload: sections };
  },
);

export const doSetLoading = createAction<PrepareAction<boolean>>('manual/sections/setLoading', (isLoading: boolean) => {
  return { payload: isLoading };
});

export const doSetLoadMore = createAction<PrepareAction<boolean>>(
  'manual/sections/setLoadMore',
  (isLoading: boolean) => {
    return { payload: isLoading };
  },
);

export const doSetError = createAction<PrepareAction<{ title: string; message: string }>>(
  'manual/sections/setError',
  (error: { title: string; message: string }) => {
    return { payload: error };
  },
);

export const doCreateManualSection = createAsyncThunk(
  'manual/sections/create',
  async (newSectionData: ManualSectionModel, { dispatch }) =>
    createManualSection(newSectionData)
      .then(() => {
        return true;
      })
      .catch((error) => {
        // dispatch(doSetError({ title: 'Create section fail', message: `${error}` }));
        return false;
      }),
);

export const doUpdateManualSection = createAsyncThunk(
  'manual/sections/update',
  async (payload: { id: string; data: ManualSectionModel }, { dispatch }) =>
    updateManualSection(payload.id, payload.data)
      .then(() => {
        return true;
      })
      .catch((error) => {
        // dispatch(doSetError({ title: 'Update section fail', message: `${error}` }));
        return false;
      }),
);

export const doDeleteManualSection = createAsyncThunk('manual/sections/delete', async (id: string) =>
  deleteManualSection(id)
    .then(() => true)
    .catch(() => false),
);

export const doUploadBanner = createAsyncThunk('manual/uploadBanner', async (banner: File, { dispatch }) => {
  const _formData = new FormData();
  _formData.append('file', banner);
  return await uploadBanner(_formData)
    .then((res) => {
      // console.log(res);
      // dispatch(doGetGeneral());
      return true;
    })
    .catch((error) => {
      // dispatch(doSetError({ title: 'Upload manual banner fail', message: `${error}` }));
      return false;
    });
});

export const doGetManualList = createAsyncThunk(
  'manual/manuals/list',
  async (payload: { id: string; pagination: ManualsPagination }, { dispatch, rejectWithValue }) => {
    // dispatch(doSetLoading(true));
    return getManualList({
      id: payload.id,
      ...payload.pagination,
      limit: MANUAL_COUNT_PER_PAGE,
    })
      .then((snapShot) => {
        const _data: ManualTableRow[] = [];
        snapShot.forEach((doc) => {
          const _item = doc.data();
          _data.push({
            id: doc.id,
            sectionId: payload.id,
            name: _item.name,
            path: _item.path,
            type: _item.type,
            downloads: _item.downloads,
            views: _item.views,
            updatedAt: _item.updatedAt,
          });
        });
        return {
          id: payload.id,
          metadata: payload.pagination,
          list: _data,
        } as ManualsMetadata;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const doRemoveManualsBySectionId = createAction<PrepareAction<string>>(
  'manual/manuals/removeManualsBySectionId',
  (sectionId: string) => {
    return { payload: sectionId };
  },
);

export const doCreateManual = createAsyncThunk(
  'manual/manuals/create',
  async (payload: { sectionId: string; name: string; file: File }, { dispatch }) => {
    const _formData = new FormData();
    _formData.append('name', payload.name);
    _formData.append('file', payload.file);
    return await createManual(payload.sectionId, _formData)
      .then(async () => {
        return true;
      })
      .catch((error) => {
        // dispatch(doSetError({ title: 'Create manual fail', message: `${error}` }));
        return false;
      });
  },
);

export const doUpdateManual = createAsyncThunk(
  'manual/manuals/update',
  async (payload: { language: string; sectionId: string; fileId: string; name: string; file?: File }, { dispatch }) => {
    const _formData = new FormData();
    _formData.append('name', payload.name);
    _formData.append('language', payload.language);
    payload.file && _formData.append('file', payload.file);
    return await updateManual(payload.sectionId, payload.fileId, _formData)
      .then(() => {
        return true;
      })
      .catch((error) => {
        // dispatch(doSetError({ title: 'Update manual fail', message: `${error}` }));
        return false;
      });
  },
);

export const doDeleteManual = createAsyncThunk(
  'manual/manuals/delete',
  async (payload: { sectionId: string; fileId: string }) =>
    deleteManual(payload.sectionId, payload.fileId)
      .then(() => true)
      .catch(() => false),
);

export const doGetBanner = createAsyncThunk('manual/banner/get', async (_, { rejectWithValue }) => {
  try {
    return (await getBanner())[0];
  } catch (error) {
    rejectWithValue(error);
  }
});

export const doUpdateBanner = createAsyncThunk(
  'manual/banner/update',
  async (payload: { data: UpdateBannerRequest; thumbnail?: File }, { dispatch }) =>
    updateBanner(payload.data)
      .then(async () => {
        if (payload.thumbnail) {
          const _formData = new FormData();
          _formData.append('lang', payload.data.language);
          _formData.append('file', payload.thumbnail);
          const resUpload = await uploadBanner(_formData).catch(() => ({ success: false } as UploadResponse));
          if (!resUpload.success) {
            // notificationController.error({
            //   message: 'Upload thumbnail',
            //   description: 'Failed',
            // });
          }
          dispatch(doGetBanner());
          return true;
        } else {
          dispatch(doGetBanner());
          return true;
        }
      })
      .catch(() => {
        return false;
      }),
);

export const manualSlice = createSlice({
  name: 'manual',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doSetError, (state, action) => {
      // console.log(action.payload);
      notificationController.error({
        message: action.payload.title,
        description: action.payload.message,
      });
    });
    builder.addCase(doGetManualSectionList.fulfilled, (state, action) => {
      state.unsubscribe = action.payload.unsubscribe;
      state.metadata = { ...state.metadata, ...action.payload.metadata };
    });
    builder.addCase(doUpdateSections, (state, action) => {
      state.sections = action.payload;
    });
    builder.addCase(doSetLoading, (state, action) => {
      state.isSectionLoading = action.payload;
    });
    builder.addCase(doSetLoadMore, (state, action) => {
      state.isSectionLoadMore = action.payload;
    });
    builder.addCase(doGetManualList.pending, (state, action) => {
      state.loadingManuals = [...state.loadingManuals, action.meta.arg.id];
    });
    builder.addCase(doGetManualList.rejected, (state, action) => {
      notificationController.error({
        message: 'Get manual fail',
        description: `${action.payload}`,
      });
      state.loadingManuals = state.loadingManuals.filter((_value) => _value !== action.meta.arg.id);
    });
    builder.addCase(doGetManualList.fulfilled, (state, action) => {
      const _oldManualIndex = state.manuals.findIndex((_value) => _value.id === action.payload.id);
      if (_oldManualIndex >= 0) {
        state.manuals[_oldManualIndex] = action.payload;
      } else {
        state.manuals = [...state.manuals, action.payload];
      }

      // set loading false
      state.loadingManuals = state.loadingManuals.filter((_value) => _value !== action.meta.arg.id);
    });
    builder.addCase(doRemoveManualsBySectionId, (state, action) => {
      state.manuals = state.manuals.filter((_value) => _value.id !== action.payload);
    });
    builder.addCase(doGetBanner.pending, (state, action) => {
      state.isBannerLoading = true;
    });
    builder.addCase(doGetBanner.fulfilled, (state, action) => {
      state.banner = action.payload;
      state.isBannerLoading = false;
    });
    builder.addCase(doGetBanner.rejected, (state, action) => {
      console.log(action.payload);
      state.isBannerLoading = false;
    });
  },
});

export default manualSlice.reducer;
