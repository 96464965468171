import { httpApi } from '@app/api/http.api';
import { ManualModel } from '@app/domain/ManualModel';
import { ManualSectionModel } from '@app/domain/ManualSectionModel';
import { PaginationOrder } from '@app/domain/PaginationModel';
import { UploadResponse } from '@app/domain/UploadResponse';
import { BannerModel } from '@app/domain/bannerModel';
import { getFirebaseFirestore } from '@app/services/firebase.service';
import {
  FirestoreError,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

export const SECTION_COUNT_PER_PAGE = 10;
export const MANUAL_COUNT_PER_PAGE = 10;

export type PaginationSortBy = 'name' | 'order';

export type ManualSortBy = 'name' | 'updatedAt';

export interface Pagination {
  page: number;
  sortBy: PaginationSortBy;
  order: PaginationOrder;
}

export interface ManualsPagination {
  page: number;
  sortBy: ManualSortBy;
  order: PaginationOrder;
}

export interface ManualSectionListRequest {
  page: number;
  limit: number;
  sortBy: PaginationSortBy;
  order: PaginationOrder;
  observer?: (snapShot: QuerySnapshot) => void;
  onError?: (error: FirestoreError) => void;
}

export interface ManualListRequest {
  id: string;
  page: number;
  limit: number;
  sortBy: ManualSortBy;
  order: PaginationOrder;
}

export interface ManualSectionTableRow extends ManualSectionModel {
  id: string;
}

export interface ManualTableRow extends ManualModel {
  id: string;
  sectionId: string;
  updatedAt?: Timestamp;
}

export interface ManualsMetadata {
  id: string;
  list: ManualTableRow[];
  metadata: ManualsPagination;
}

export interface CreateManualResponse {
  success: string;
}

export type UpdateBannerRequest = Omit<BannerModel, 'thumbnail' | 'updatedAt' | 'createdAt'>;

export const getManualSectionsCount = async () => {
  const _ManualsFCRef = collection(getFirebaseFirestore(), 'manuals');
  return (await getCountFromServer(query(_ManualsFCRef))).data().count;
};

export const getManualSectionList = (payload: ManualSectionListRequest) => {
  const _ManualsFCRef = collection(getFirebaseFirestore(), 'manuals');
  const _queryConstraint: QueryConstraint[] = [];
  if (payload.sortBy) {
    _queryConstraint.push(orderBy(payload.sortBy, payload.order));
  }

  const q = query(_ManualsFCRef, ..._queryConstraint, limit(payload.limit * payload.page));
  return onSnapshot(
    q,
    payload.observer ??
      (() => {
        return;
      }),
    payload.onError ??
      (() => {
        return;
      }),
  );
};

export const createManualSection = (newSectionData: ManualSectionModel): Promise<undefined> =>
  httpApi.post<undefined>('/manuals', { ...newSectionData }).then(({ data }) => data);

export const updateManualSection = (id: string, updateSectionData: ManualSectionModel): Promise<undefined> =>
  httpApi.put<undefined>(`/manuals/${id}`, { ...updateSectionData }).then(({ data }) => data);

export const deleteManualSection = (id: string): Promise<undefined> =>
  httpApi.delete<undefined>(`/manuals/${id}`).then(({ data }) => data);

export const getBanner = () => httpApi.get<BannerModel[]>('/banners').then(({ data }) => data);

export const uploadBanner = async (payload: FormData): Promise<UploadResponse> => {
  // get first banner id
  const _BannersFCRef = collection(getFirebaseFirestore(), 'banners');
  const bannerId = (await getDocs(_BannersFCRef)).docs[0]?.id;
  if (bannerId === null || bannerId === undefined || bannerId === '') {
    return { success: false };
  }
  return await httpApi.patch<UploadResponse>(`/banners/${bannerId}/thumbnail`, payload).then(({ data }) => data);
};

export const updateBanner = async (data: UpdateBannerRequest): Promise<undefined> => {
  const _BannersFCRef = collection(getFirebaseFirestore(), 'banners');
  const bannerId = (await getDocs(_BannersFCRef)).docs[0]?.id;
  if (bannerId === null || bannerId === undefined || bannerId === '') {
    throw Error('no banner id');
  }
  return await httpApi.put<undefined>(`/banners/${bannerId}`, { ...data }).then(({ data }) => data);
};

export const getManualList = async (payload: ManualListRequest) => {
  const _ManualsFCRef = collection(getFirebaseFirestore(), 'manuals', payload.id, 'files');

  const _queryConstraint: QueryConstraint[] = [];
  if (payload.sortBy) {
    _queryConstraint.push(orderBy(payload.sortBy, payload.order));
  }

  const q = query(_ManualsFCRef, ..._queryConstraint, limit(payload.limit * payload.page));
  return await getDocs(q);
};

export const createManual = (sectionId: string, payload: FormData): Promise<CreateManualResponse> =>
  httpApi.post<CreateManualResponse>(`/manuals/${sectionId}/files`, payload).then(({ data }) => data);

export const updateManual = (sectionId: string, fileId: string, payload: FormData): Promise<CreateManualResponse> =>
  httpApi.put<CreateManualResponse>(`/manuals/${sectionId}/files/${fileId}`, payload).then(({ data }) => data);

export const deleteManual = (sectionId: string, fileId: string): Promise<undefined> =>
  httpApi.delete<undefined>(`/manuals/${sectionId}/files/${fileId}`).then(({ data }) => data);
