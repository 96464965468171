import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './SiderEditFormMenu.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { UpdateFieldModal } from '@app/components/formManager/UpdateFieldModal/UpdateFieldModal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ColumnsType } from 'antd/es/table';
import { FieldModel } from '@app/domain/FormModel';
import { ConfigProvider, Space, TableProps } from 'antd';
import { doDeleteFormField, doMoveFormField } from '@app/store/slices/formSlice';
import { DraggableBodyRow } from '@app/components/common/Table/DraggableBodyRow/DraggableBodyRow';
import { CreateFieldModal } from '@app/components/formManager/CreateFieldModal/CreateFieldModal';

const Table: React.FC<TableProps<any>> = (props) => {
  return <S.Table {...props} />;
};

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

interface FieldColumn extends FieldModel {
  index: number;
}

const SiderEditFormMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  // const location = useLocation();
  const dispatch = useAppDispatch();

  const { formData, isLoadingFormData, isActionLoading } = useAppSelector((state) => state.form);

  const [isCreateOpen, setCreateOpen] = useState(false);
  const [currentFieldIndex, setCurrentFieldIndex] = useState<number>();
  const [isEditOpen, setEditOpen] = useState(false);

  const fields = useMemo(() => {
    return formData?.fields?.map((_value, index) => ({ ..._value, index } as FieldColumn)) ?? [];
  }, [formData?.fields]);

  const columns: ColumnsType<FieldColumn> = [
    {
      dataIndex: 'label',
      key: 'label',
      render: (label: string, record) => {
        return (
          <a
            onClick={() => {
              if (isActionLoading) {
                return;
              }
              setCurrentFieldIndex(record.index);
              setEditOpen(true);
            }}
          >
            {label}
          </a>
        );
      },
    },
    {
      dataIndex: 'index',
      key: 'index',
      render: (index) => {
        return (
          <Space>
            <a
              onClick={() => {
                setCurrentFieldIndex(index);
                setEditOpen(true);
              }}
            >
              <EditFilled />
            </a>
            <a
              onClick={() => {
                dispatch(doDeleteFormField({ index }));
              }}
            >
              <DeleteFilled />
            </a>
          </Space>
        );
      },
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      // console.log(dragIndex, hoverIndex);
      if (dragIndex !== hoverIndex) {
        dispatch(doMoveFormField({ fromIndex: dragIndex, toIndex: hoverIndex }));
      }
    },
    [dispatch],
  );

  return (
    <>
      {/* <S.Menu
        mode="inline"
        defaultSelectedKeys={['DynamicFormFields']}
        defaultOpenKeys={['DynamicFormFields']}
        onClick={() => setCollapsed(true)}
        items={[
          {
            key: `DynamicFormFields`,
            title: formData?.name,
            label: formData?.name,
            children: formData?.fields.map((field, index) => ({
              key: `DynamicFormField_${index}`,
              icon: <BulbOutlined />,
              label: (
                <Link
                  to={'#'}
                  onClick={() => {
                    setCurrentFieldIndex(index);
                    setEditOpen(true);
                  }}
                >
                  {field.label}
                </Link>
              ),
              title: field.name,
            })),
          },
        ]}
      /> */}
      <S.HeaderTitle>{formData?.name}</S.HeaderTitle>
      <DndProvider backend={HTML5Backend}>
        <ConfigProvider
          renderEmpty={() => {
            return <div />;
          }}
        >
          <Table
            // className="fields-update-table"
            style={{ paddingLeft: '30px' }}
            columns={columns}
            dataSource={fields}
            components={components}
            onRow={(_, index) => {
              const attr = {
                index,
                moveRow,
              };
              return attr as React.HTMLAttributes<any>;
            }}
            loading={isLoadingFormData}
            // bordered
            showHeader={false}
            pagination={false}
            rowKey={(record) => record.index}
          />
        </ConfigProvider>
      </DndProvider>
      {!isLoadingFormData && (
        <S.Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => {
            if (isActionLoading) {
              return;
            }
            setCreateOpen(true);
          }}
        >
          Create new field
        </S.Button>
      )}
      <CreateFieldModal
        open={isCreateOpen}
        onCancel={() => {
          setCreateOpen(false);
        }}
        onFinish={() => {
          setTimeout(() => {
            setCreateOpen(false);
          }, 250);
        }}
      />
      {currentFieldIndex !== undefined ? (
        <UpdateFieldModal
          index={currentFieldIndex}
          open={isEditOpen}
          data={formData?.fields?.[currentFieldIndex]}
          onCancel={() => {
            setCurrentFieldIndex(undefined);
            setEditOpen(false);
          }}
          onFinish={() => {
            setTimeout(() => {
              setCurrentFieldIndex(undefined);
              setEditOpen(false);
            }, 250);
          }}
        />
      ) : null}
    </>
  );
};

export default SiderEditFormMenu;
