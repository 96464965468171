import { httpApi } from '@app/api/http.api';

export const getGeneral = () => {
  return httpApi.get('/app');
};

export const getTranslations = async (basePath: string, id: string) => {
  const response = await httpApi.get(`${basePath}/${id}/translations`);
  return response.data;
};

export const getStats = () => {
  return httpApi.get('/stats');
};
