import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GeneralModel } from '@app/domain/GeneralModel';
import { getGeneral } from '@app/api/general.api';

export interface GeneralState {
  general?: GeneralModel;
  isLoading: boolean;
  errorMsg?: string;
}

const initialState: GeneralState = {
  isLoading: false,
};

export const doGetGeneral = createAsyncThunk('general/doGet', async () =>
  getGeneral().then((data) => {
    // console.log(data.data);
    return data.data;
  }),
);

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getForms.fulfilled, (state, action) => {
    //   state.forms = action.payload;
    // });
    builder.addCase(doGetGeneral.fulfilled, (state, action) => {
      state.general = action.payload;
      state.isLoading = false;
    });
    builder.addCase(doGetGeneral.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(doGetGeneral.rejected, (state, action) => {
      state.errorMsg = 'Can not get general data';
      state.isLoading = false;
    });
  },
});

export default generalSlice.reducer;
