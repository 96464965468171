import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Space, Spin, Tooltip } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  Pagination,
  PaginationSortBy,
  SUBMISSION_COUNT_PER_PAGE,
  SubmissionTableRow,
  getSubmissionsAll,
} from '@app/api/submission.api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import dayjs from 'dayjs';
// import { BASE_COLORS } from '@app/styles/themes/constants';
import { ArrowLeftOutlined, CaretDownFilled, CaretUpFilled, DownloadOutlined, SwapOutlined } from '@ant-design/icons';
import { doGetSubmissions } from '@app/store/slices/submissionSlice';
import { AnswerModel } from '@app/domain/SubmissionModel';
import { SubmissionDetailModal } from '@app/components/submission/SubmissionDetailModal/SubmissionDetailModal';
import { getFormDetail } from '@app/api/form.api';
import * as xlsx from 'xlsx';
import { doGetFormDetail } from '@app/store/slices/formSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { FORM_FIELD_PREFIX_KEY } from '@app/constants/config/forms';
import { Timestamp } from 'firebase/firestore';

const FormReportPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { formId } = useParams<{ formId?: string }>();

  const { formData, isLoadingFormData } = useAppSelector((state) => state.form);
  const { submissions, unsubscribe, isSubmissionsLoading, isSubmissionsLoadMore, metadata } = useAppSelector(
    (state) => state.submission,
  );

  const { isMounted } = useMounted();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [currentSubmission, setCurrentSubmission] = useState<SubmissionTableRow>();

  const [isLoading, setLoading] = useState(false);

  const fetch = useCallback(
    (payload: { formId: string; pagination: Pagination }) => {
      dispatch(doGetSubmissions(payload));
    },
    [dispatch],
  );

  const fetchFormDetail = useCallback(
    (formId: string) => {
      dispatch(doGetFormDetail(formId));
    },
    [dispatch],
  );

  useEffect(() => {
    // console.log('watch manuals', pagination.current);
    if (formId) {
      fetch({ pagination: metadata, formId: formId });
      if (!formData || formData.id !== formId) {
        fetchFormDetail(formId);
      }
    }
    return () => {
      // console.log('unsubscribe forms');
      unsubscribe?.();
    };
  }, [isMounted]);

  const hideDetailModal = () => {
    setIsDetailOpen(false);
  };

  const onExportPress = async () => {
    if (formId) {
      setLoading(true);
      const _sheet: string[][] = [];
      const _formData = await getFormDetail({ id: formId });
      if (_formData) {
        const _submissionsAll = await getSubmissionsAll(formId);
        // sort all data to 1 object

        let _headerData: { key: string; label: string }[] = [];
        _formData.fields?.forEach((_value) => {
          _headerData.push({ key: _value.name, label: _value.label });
        });

        const _bodyData: { [key: string]: string }[] = [];

        _submissionsAll.docs.forEach((doc) => {
          const _data = doc.data();
          const _answers = _data.answers as AnswerModel[];
          const _item: { [key: string]: string } = {
            '#!@submissionId': doc.id,
            '#!@createdAt': _data.createdAt?.seconds
              ? dayjs(_data.createdAt.seconds * 1000).format('DD/MM/YYYY, HH:mm:ss')
              : '',
          };
          _answers.forEach((_value) => {
            const _headerItem = _headerData.find((_item) => _item.key === _value.name);
            if (!_headerItem) {
              _headerData.push({ key: _value.name, label: _value.label });
            }
            _item[_value.name] = _value.value.join('\n');
          });
          _bodyData.push(_item);
        });

        // sort and add id, Submitted at
        _headerData = _headerData.sort((a, b) => a.key.localeCompare(b.key));
        _headerData = [
          { key: '#!@submissionId', label: 'id' },
          { key: '#!@createdAt', label: 'Submitted at' },
          ..._headerData,
        ];

        // xlsx header
        const _headerRow: string[] = [];
        _headerData.forEach((_value) => {
          _headerRow.push(_value.label);
        });

        _sheet.push(_headerRow);

        // xlsx body
        _bodyData.forEach((_item) => {
          const _row: string[] = [];
          _headerData.forEach((_value) => {
            _row.push(_item[_value.key]);
          });

          _sheet.push(_row);
        });

        // save xlsx
        try {
          const wb = xlsx.utils.book_new();
          const ws = xlsx.utils.aoa_to_sheet(_sheet);
          xlsx.utils.book_append_sheet(wb, ws, _formData.name);
          xlsx.writeFile(wb, _formData.name && _formData.name !== '' ? `${_formData.name}.xlsx` : 'form_report.xlsx');
        } catch (error) {
          console.log(error);
        }
      }
      setLoading(false);
    }
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (formId) {
      if (metadata.sortBy === sortBy) {
        unsubscribe?.();
        fetch({
          formId,
          pagination: {
            ...metadata,
            sortBy: sortBy,
            order: metadata.order === 'asc' ? 'desc' : 'asc',
          },
        });
      } else {
        unsubscribe?.();
        fetch({ formId, pagination: { ...metadata, sortBy: sortBy, order: 'asc' } });
      }
    }
  };

  const getSubmitterName = (answers: AnswerModel[]) => {
    let _name = '';
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].name === 'firstname') {
        const _firstName = answers[index].value.join(' ');
        _name = `${_firstName} ${_name}`;
      } else if (answers[index].name === 'lastname') {
        const _lastName = answers[index].value.join(' ');
        _name = `${_name} ${_lastName}`;
      } else if (answers[index].name === 'name') {
        const _fullName = answers[index].value.join(' ');
        _name = _fullName;
        break;
      }
    }
    return _name;
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (metadata.sortBy === key) {
      if (metadata.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns: ColumnsType<SubmissionTableRow> = useMemo(() => {
    const _formFieldKeys: { key: string; label: string }[] = [];
    const _submissionCol: ColumnsType<SubmissionTableRow> = [];
    submissions?.forEach((_submission) => {
      const _submissionFieldKeys = Object.keys(_submission).filter(
        (_key) =>
          _key.startsWith(FORM_FIELD_PREFIX_KEY) &&
          (_formFieldKeys.length <= 0 || !_formFieldKeys.some((_value) => _value.key === _key)),
      );
      _submissionFieldKeys.forEach((_key) => {
        const _answer = _submission[_key] as AnswerModel;
        _formFieldKeys.push({
          key: _key,
          label: _answer.label,
        });
      });
    });

    _formFieldKeys.forEach((_value) => {
      _submissionCol.push({
        title: _value.label,
        dataIndex: _value.key,
        key: _value.key,
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (data: AnswerModel) => {
          const _text = data?.value.join('\n') ?? '';
          return (
            <Tooltip placement="topLeft" title={_text} overlayStyle={{ maxWidth: '500px', whiteSpace: 'pre-line' }}>
              <span style={{ whiteSpace: 'pre-line' }}>{_text}</span>
            </Tooltip>
          );
        },
      });
    });
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 220,
      },
      // {
      //   title: 'Submitter',
      //   dataIndex: 'answers',
      //   key: 'answers',
      //   // width: 300,
      //   render: (answers: AnswerModel[]) => {
      //     return <span>{getSubmitterName(answers)}</span>;
      //   },
      //   sorter: (a, b) =>
      //     getSubmitterName(a.answers).toLowerCase().localeCompare(getSubmitterName(b.answers).toLowerCase()),
      // },
      ..._submissionCol,
      {
        // title: () => renderSorterHeader('Created at', 'createdAt'),
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 130,
        render: (date: Timestamp) => (
          <span>{date ? dayjs(date.seconds * 1000).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>
        ),
        sorter: (a, b) => (a.createdAt && b.createdAt ? a.createdAt.seconds - b.createdAt.seconds : 0),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (_, record) => {
          return (
            <Space wrap>
              <Button
                type="link"
                onClick={() => {
                  setIsDetailOpen(true);
                  setCurrentSubmission(record);
                }}
              >
                View
              </Button>
            </Space>
          );
        },
      },
    ];
  }, [submissions]);

  return (
    <>
      <Card
        padding="0 1.875rem 1.875rem 1.875rem"
        title={
          <Space style={{ marginBottom: 20 }} size={[20, 0]}>
            <a
              onClick={() => {
                if (location.key !== 'default') {
                  navigate(-1);
                } else {
                  navigate('/form/list');
                }
              }}
            >
              <ArrowLeftOutlined />
            </a>
            <span>{`Form report: ${formData?.name ?? ''}`}</span>
          </Space>
        }
        extra={
          <div style={{ display: 'flex' }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={onExportPress}
              disabled={isSubmissionsLoading || isLoading}
            >
              Export
            </Button>
          </div>
        }
      >
        <InfiniteScroll
          dataLength={submissions.length}
          next={() => {
            if (formId && !isSubmissionsLoading && !isSubmissionsLoadMore) {
              unsubscribe?.();
              fetch({ formId, pagination: { ...metadata, page: metadata.page + 1 } });
            }
          }}
          hasMore={submissions.length >= metadata.page * SUBMISSION_COUNT_PER_PAGE}
          loader={
            <SpinnerWrapper>
              <Spin size="large" />
            </SpinnerWrapper>
          }
          scrollableTarget={'main-content'}
        >
          <Table
            // rowSelection={rowSelection}
            columns={columns}
            pagination={false}
            dataSource={submissions}
            loading={isSubmissionsLoading}
            // onChange={handleTableChange}
            scroll={{ x: 450 + columns.length * 150 }}
            bordered
            rowKey={(record: SubmissionTableRow) => record.id}
            key={'form_report_manager'}
          />
        </InfiniteScroll>
        {/* {submissions.length >= metadata.page * SUBMISSION_COUNT_PER_PAGE ? (
          <Space style={{ marginTop: '1.5rem', marginBottom: '3rem', marginLeft: '1.875rem' }}>
            <Button
              type="dashed"
              onClick={() => {
                if (formId) {
                  unsubscribe?.();
                  fetch({ formId, pagination: { ...metadata, page: metadata.page + 1 } });
                }
              }}
              loading={isSubmissionsLoading}
            >
              {t('manual.loadMoreSections')}
            </Button>
          </Space>
        ) : null} */}
      </Card>
      <SubmissionDetailModal
        data={currentSubmission}
        open={isDetailOpen}
        onCancel={() => {
          setCurrentSubmission(undefined);
          hideDetailModal();
        }}
      />
    </>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export default FormReportPage;
