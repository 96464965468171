import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { doUpdateManualSection } from '@app/store/slices/manualSlice';
import { ManualSectionTableRow } from '@app/api/manual.api';
import { getTranslations } from '@app/api/general.api';
import { ManualSectionModel } from '@app/domain/ManualSectionModel';
import { Languages } from '@app/components/Languages';

interface UpdateManualSectionModalProps {
  data?: ManualSectionTableRow;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface NewFormData {
  name: string;
  order?: number;
}

export const UpdateManualSectionModal: React.FC<UpdateManualSectionModalProps> = ({
  data,
  open,
  onCancel,
  onFinish,
}) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en');

  useResetFormOnCloseModal({
    form,
    open,
  });

  useEffect(() => {
    if (open && data) {
      form.setFieldsValue({ ...data });
      fetchTranslations();
    }
  }, [open, data]);

  useEffect(() => {
    const values = translations?.[language] ?? {};
    form.resetFields();
    form.setFieldsValue({ ...values });
  }, [language]);

  const fetchTranslations = async () => {
    if (data && data.id) {
      const translations = await getTranslations('/manuals', data.id);
      translations['en'] = { ...data };
      setTranslations(translations);
      setLanguage('en');
    }
  };

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: NewFormData) => {
    if (!data?.id) {
      return;
    }
    setLoading(true);
    // console.log(_file);

    dispatch(
      doUpdateManualSection({
        id: data.id,
        data: {
          language,
          name: values.name,
          order: values.order ?? 999,
        },
      }),
    )
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Update section',
            description: 'Successfully',
          });
          onFinish();
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal title="Update Section" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <Languages selected={language} onChange={setLanguage} />
      <BaseForm form={form} initialValues={data} layout="vertical" name="CreateForm" onFinish={handleSubmit}>
        <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        {language === 'en' && (
          <BaseForm.Item name="order" label="Order number" rules={[{ required: false }, { type: 'integer', min: 0 }]}>
            <InputNumber />
          </BaseForm.Item>
        )}
      </BaseForm>
    </Modal>
  );
};
