import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { doCreateManualSection } from '@app/store/slices/manualSlice';

interface CreateManualSectionModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
}
interface NewFormData {
  name: string;
  order?: number;
}

export const CreateManualSectionModal: React.FC<CreateManualSectionModalProps> = ({ open, onCancel, onFinish }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: NewFormData) => {
    setLoading(true);
    // console.log(_file);

    dispatch(doCreateManualSection({ ...values, order: values.order ?? 999 }))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Create new section',
            description: 'Successfully',
          });
          onFinish();
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Modal title="Create Section" open={open} onOk={onOk} onCancel={onFormCancel} confirmLoading={isLoading}>
      <BaseForm form={form} layout="vertical" name="CreateForm" onFinish={handleSubmit}>
        <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="order" label="Order number" rules={[{ required: false }, { type: 'integer', min: 0 }]}>
          <InputNumber />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
