import React from 'react';
import * as S from './TextEditor.styles';
import { ReactQuillProps } from 'react-quill';

export interface ITag {
  id: string;
  title: string;
  bgColor: 'error' | 'success' | 'warning';
}

type TextEditorProps = ReactQuillProps;

export const TextEditor: React.FC<TextEditorProps> = ({ ...otherProps }) => {
  return (
    <S.TextEditorWrapper
      theme="snow"
      modules={{
        toolbar: [
          [{ header: [false, 3, 2, 1] }],
          [{ size: ['0.75em', false, '1.5em', '2.5em'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
          ['link'],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'align',
        'link',
      ]}
      {...otherProps}
    />
  );
};
