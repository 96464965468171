import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
// import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { SubmissionTableRow } from '@app/api/submission.api';

interface SubmissionDetailModalProps {
  data?: SubmissionTableRow;
  open: boolean;
  onCancel: () => void;
}

export const SubmissionDetailModal: React.FC<SubmissionDetailModalProps> = ({ data, open, onCancel }) => {
  // const { t } = useTranslation();
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    if (open) {
      setCurrentData(data);
    }
  }, [open, data]);

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  return (
    <Modal
      title="Submission report"
      open={open}
      onOk={onFormCancel}
      onCancel={onFormCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <BaseForm layout="vertical">
        {currentData?.answers.map((_value, index) => {
          return (
            <BaseForm.Item key={`submission_field_${_value.name}_${index}`} label={_value.label}>
              <span style={{ whiteSpace: 'pre-line' }}>{_value.value.join('\n')}</span>
            </BaseForm.Item>
          );
        }) ?? null}
      </BaseForm>
    </Modal>
  );
};
