import { Timestamp } from 'firebase/firestore';

export interface FormModel {
  lang?: string;
  name?: string;
  order?: number;
  submissions?: number;
  status?: 'public' | 'draft';
  fields?: FieldModel[];
  thumbnail?: string;
  pages?: string[];
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  preText?: string;
  postText?: string;
  submissionMessage?: string;
  submissionReceivers?: string;
  subtitle?: string;
  buttonText?: string;
}

export interface FieldModel {
  label: string;
  name: string;
  type: FieldType;
  options?: string[];
  __options?: {
    [key: string]: string[];
  };
  trans?: {
    [key: string]: string;
  };
}

export interface FlatFieldData extends Pick<FieldModel, 'label' | 'name' | 'type' | 'options'> {
  [label_code: string]: string | FieldType | string[] | undefined;
}

export const fieldTypeList = ['text', 'number', 'paragraph', 'single-choice', 'multiple-choice'] as const;

export type FieldType = (typeof fieldTypeList)[number];
