import { httpApi } from '@app/api/http.api';
import { DeviceModelData } from '@app/domain/DeviceModelData';
import { getFirebaseFirestore } from '@app/services/firebase.service';
import { FirestoreError, QuerySnapshot, collection, getCountFromServer, onSnapshot, query } from 'firebase/firestore';

export interface ModelListRequest {
  observer?: (snapShot: QuerySnapshot) => void;
  onError?: (error: FirestoreError) => void;
}

export interface ModelTableRow extends DeviceModelData {
  id: string;
}

export type CreateModelRequest = Omit<DeviceModelData, 'name'>;

export const getModelsCount = async () => {
  const _ModelsFCRef = collection(getFirebaseFirestore(), 'models');
  return (await getCountFromServer(query(_ModelsFCRef))).data().count;
};

export const getModelList = (payload: ModelListRequest) => {
  // console.log(payload);
  const _ModelsFCRef = collection(getFirebaseFirestore(), 'models');

  const q = query(_ModelsFCRef);
  return onSnapshot(
    q,
    payload.observer ??
      (() => {
        return;
      }),
    payload.onError ??
      (() => {
        return;
      }),
  );
};

export const createModel = async (payload: CreateModelRequest) =>
  httpApi.post(`/models`, { ...payload }).then(({ data }) => data);
