import ReactQuill, { Quill } from 'react-quill';
import styled from 'styled-components';

const ColorClass = Quill.import('attributors/class/color');
const SizeStyle = Quill.import('attributors/style/size');
// const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const DirectionStyle = Quill.import('attributors/style/direction');

SizeStyle.whitelist = ['0.75em', '1em', '1.5em', '2.5em'];

Quill.register(ColorClass, true);
Quill.register(SizeStyle, true);
// Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(DirectionStyle, true);

export const TextEditorWrapper = styled(ReactQuill)`
  & .ql-editor {
    min-height: 150px;
    max-height: 150px;
  }

  & .ql-picker {
    color: var(--text-main-color);
  }

  & .ql-picker-label {
    color: var(--text-main-color);
  }

  & .ql-picker-options {
    background-color: var(--layout-body-bg-color);
  }

  & .ql-stroke {
    stroke: var(--text-main-color);
  }

  & .ql-fill {
    fill: var(--text-main-color);
  }

  & .ql-active .ql-stroke {
    stroke: var(--text-sider-primary-color) !important;
  }

  & .ql-active .ql-fill {
    fill: var(--text-sider-primary-color) !important;
  }

  & .ql-editing input {
    background-color: var(--layout-body-bg-color);
    color: var(--text-main-color);
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='0.75em']::before {
    content: 'Small';
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='1em']::before {
    content: 'Normal';
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='1.5em']::before {
    content: 'Large';
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='2.5em']::before {
    content: 'Huge';
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='0.75em']::before {
    content: 'Small';
    font-size: 0.75em !important;
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='1em']::before {
    content: 'Normal';
    font-size: 1em !important;
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='1.5em']::before {
    content: 'Large';
    font-size: 1.5em !important;
  }

  & .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='2.5em']::before {
    content: 'Huge';
    font-size: 2.5em !important;
  }

  & .ql-editor h1 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  & .ql-editor h2 {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  & .ql-editor h3 {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
`;
