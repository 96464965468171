import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Space, Tooltip, Image, Spin } from 'antd';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { COUPON_COUNT_PER_PAGE, CouponTableRow, Pagination, PaginationSortBy } from '@app/api/coupon.api';
import { doDeleteCoupon, doGetCoupons, doUpdateCouponDetail } from '@app/store/slices/couponSlice';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import dayjs from 'dayjs';
import { CaretDownFilled, CaretUpFilled, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { doGetModels } from '@app/store/slices/modelSlice';
import { CreateCouponModal } from '@app/components/coupon/CreateCouponModal/CreateCouponModal';
import { RedemptionModel } from '@app/domain/CouponModel';
import { UpdateCouponModal } from '@app/components/coupon/UpdateCouponModal/UpdateCouponModal';
import { CreateDeviceModelModal } from '@app/components/deviceModel/CreateDeviceModelModal/CreateDeviceModelModal';
import { CouponDetailModal } from '@app/components/coupon/CouponDetailModal/CouponDetailModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

const CouponManagerPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const general = useAppSelector((state) => state.general.general);
  const { coupons, unsubscribe, isCouponsLoading, isCouponsLoadMore, metadata } = useAppSelector(
    (state) => state.coupon,
  );
  const { unsubscribe: unsubscribeModel } = useAppSelector((state) => state.model);

  const { isMounted } = useMounted();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState<CouponTableRow>();
  const [isCreateModelOpen, setIsCreateModelOpen] = useState(false);

  const dateNow = useMemo(() => new Date(), []);

  const fetch = useCallback(
    (payload: Pagination) => {
      dispatch(doGetCoupons(payload));
    },
    [dispatch],
  );

  const fetchModels = useCallback(() => {
    dispatch(doGetModels());
  }, [dispatch]);

  useEffect(() => {
    // console.log('watch manuals', pagination.current);
    fetch({ ...metadata });
    return () => {
      // console.log('unsubscribe forms');
      unsubscribe?.();
    };
  }, [isMounted]);

  useEffect(() => {
    fetchModels();
    return () => {
      unsubscribeModel?.();
    };
  }, [isMounted]);

  const handleDelete = (rowId: string) => {
    dispatch(doDeleteCoupon(rowId))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: 'Delete coupon',
          description: 'Successfully',
        });
        // refreshPage();
      });
  };

  const hideCreateModal = () => {
    setIsCreateOpen(false);
  };

  const hideEditModal = () => {
    setIsEditOpen(false);
  };

  const hideCreateModelModal = () => {
    setIsCreateModelOpen(false);
  };

  const hideDetailModal = () => {
    setIsDetailOpen(false);
  };

  const onSorterPress = (sortBy: PaginationSortBy) => {
    if (isCouponsLoading || isCouponsLoadMore) {
      return;
    }
    if (metadata.sortBy === sortBy) {
      unsubscribe?.();
      fetch({
        ...metadata,
        sortBy: sortBy,
        order: metadata.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      unsubscribe?.();
      fetch({ ...metadata, sortBy: sortBy, order: 'asc' });
    }
  };

  const renderSorterHeader = (title: string, key: PaginationSortBy) => {
    let _tooltip = t('dataDisplay.pagination.clickToSortAsc');
    let _orderAsc: boolean | undefined = undefined;
    if (metadata.sortBy === key) {
      if (metadata.order === 'asc') {
        _tooltip = t('dataDisplay.pagination.clickToSortDesc');
        _orderAsc = true;
      } else {
        _tooltip = t('dataDisplay.pagination.clickToSortAsc');
        _orderAsc = false;
      }
    }
    return (
      <Tooltip placement="topLeft" title={_tooltip}>
        <a onClick={() => onSorterPress(key)}>
          {title}{' '}
          {_orderAsc !== undefined ? (
            _orderAsc === true ? (
              <CaretUpFilled size={14} />
            ) : (
              <CaretDownFilled size={14} />
            )
          ) : (
            <SwapOutlined size={14} />
          )}
        </a>
      </Tooltip>
    );
  };

  const columns: ColumnsType<CouponTableRow> = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Icon',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 190,
      render: (thumbnail: string, record, index) => {
        const _url =
          general?.publicPath && thumbnail && thumbnail !== ''
            ? `${general?.publicPath}/${thumbnail}?alt=media&xplorehash=${record.updatedAt?._seconds ?? dateNow}`
            : undefined;
        return (
          <Space size="middle">
            <Image key={`form_thumbnail_${index}`} preview={false} width={150} src={_url} />
          </Space>
        );
      },
    },
    //TODO: change way of preview file instructions
    // {
    //   title: 'Instructions',
    //   dataIndex: 'instructions',
    //   key: 'instructions',
    //   width: 190,
    //   render: (instructions: string, record, index) => {
    //     const _url =
    //       general?.publicPath && instructions && instructions !== ''
    //         ? `${general?.publicPath}/${instructions}?alt=media&xplorehash=${dateNow}`
    //         : undefined;
    //     return (
    //       <Space size="middle">
    //         <Image key={`form_instruction_${index}`} preview={false} width={150} src={_url} />
    //       </Space>
    //     );
    //   },
    // },
    {
      title: () => renderSorterHeader('Name', 'name'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record) => {
        return (
          <a
            onClick={() => {
              setCurrentCoupon(record);
              setIsDetailOpen(true);
            }}
          >
            {name}
          </a>
        );
      },
    },
    {
      // title: () => renderSorterHeader('Started at', 'startedAt'),
      title: 'Started at',
      dataIndex: 'startedAt',
      key: 'startedAt',
      width: 130,
      render: (date: string) => <span>{date ? dayjs(date).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>,
    },
    {
      // title: () => renderSorterHeader('Expired at', 'expiredAt'),
      title: 'Expired at',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      width: 130,
      render: (date: string) => <span>{date ? dayjs(date).format('DD/MM/YYYY, HH:mm:ss') : ''}</span>,
    },
    {
      title: 'Redeemed',
      dataIndex: 'redemptions',
      key: 'redemptions',
      width: 100,
      render: (redemptions: RedemptionModel[]) => <span>{redemptions?.length ?? 0}</span>,
    },
    {
      title: 'Available',
      dataIndex: 'availableCodes',
      key: 'availableCodes',
      width: 100,
      render: (availableCodes: string[]) => <span>{availableCodes?.length ?? 0}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (id, record) => {
        return (
          <Space wrap>
            <Button
              type="link"
              onClick={() => {
                setCurrentCoupon(record);
                setIsDetailOpen(true);
              }}
            >
              View
            </Button>
            <Button
              type="link"
              onClick={() => {
                // open update coupon modal
                setCurrentCoupon(record);
                setIsEditOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this coupon?"
              okText="Yes"
              cancelText="No"
              placement="left"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
            <Button
              type="link"
              onClick={() => {
                dispatch(doUpdateCouponDetail({ ...record }));
                navigate(`/coupon/report/${id}`);
              }}
            >
              View Report
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card
        padding="0 1.875rem 1.875rem 1.875rem"
        title="Coupon list"
        extra={
          <div style={{ display: 'flex' }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsCreateOpen(true)}
              disabled={isCouponsLoading || isCouponsLoadMore}
            >
              Create new
            </Button>
          </div>
        }
      >
        <InfiniteScroll
          dataLength={coupons.length}
          next={() => {
            if (!isCouponsLoading && !isCouponsLoadMore) {
              unsubscribe?.();
              fetch({ ...metadata, page: metadata.page + 1 });
            }
          }}
          hasMore={coupons.length >= metadata.page * COUPON_COUNT_PER_PAGE}
          loader={
            <SpinnerWrapper>
              <Spin size="large" />
            </SpinnerWrapper>
          }
          scrollableTarget={'main-content'}
        >
          <Table
            // rowSelection={rowSelection}
            columns={columns}
            pagination={false}
            dataSource={coupons}
            loading={isCouponsLoading}
            // onChange={handleTableChange}
            scroll={{ x: 1300 }}
            bordered
            rowKey={(record: CouponTableRow) => record.id}
            key={'coupon_manager'}
          />
        </InfiniteScroll>
        {/* {coupons.length >= metadata.page * COUPON_COUNT_PER_PAGE ? (
          <Space style={{ marginTop: '1.5rem', marginBottom: '3rem', marginLeft: '1.875rem' }}>
            <Button
              type="dashed"
              onClick={() => {
                unsubscribe?.();
                fetch({ ...metadata, page: metadata.page + 1 });
              }}
              loading={isCouponsLoading}
            >
              {t('manual.loadMoreSections')}
            </Button>
          </Space>
        ) : null} */}
      </Card>
      <CouponDetailModal
        data={currentCoupon}
        open={isDetailOpen}
        onCancel={() => {
          setCurrentCoupon(undefined);
          hideDetailModal();
        }}
      />
      <CreateCouponModal
        open={isCreateOpen}
        onCancel={hideCreateModal}
        onFinish={() =>
          setTimeout(() => {
            hideCreateModal();
          }, 250)
        }
        openCreateModelModal={() => {
          setIsCreateModelOpen(true);
        }}
      />
      <UpdateCouponModal
        data={currentCoupon}
        open={isEditOpen}
        onCancel={() => {
          setCurrentCoupon(undefined);
          hideEditModal();
        }}
        onFinish={() =>
          setTimeout(() => {
            setCurrentCoupon(undefined);
            hideEditModal();
          }, 250)
        }
        openCreateModelModal={() => {
          setIsCreateModelOpen(true);
        }}
      />
      <CreateDeviceModelModal
        open={isCreateModelOpen}
        onCancel={hideCreateModelModal}
        onFinish={() =>
          setTimeout(() => {
            hideCreateModelModal();
          }, 250)
        }
      />
    </>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export default CouponManagerPage;
