import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Col, Row, Image, Typography, Space } from 'antd';
import { doGetFormDetail, doUpdateForm } from '@app/store/slices/formSlice';
import { Card } from '@app/components/common/Card/Card';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { FormModel } from '@app/domain/FormModel';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { Switch } from '@app/components/common/Switch/Switch';
import { notificationController } from '@app/controllers/notificationController';
import { Upload } from '@app/components/common/Upload/Upload';
import { getFileUrl, resizeThumbnail } from '@app/utils/utils';
import { FieldsPreview } from '@app/components/formManager/FieldsPreview/FieldsPreview';
import 'react-quill/dist/quill.snow.css';
import { TextEditor } from '@app/components/common/TextEditor/TextEditor';
import sanitizeHtml from 'sanitize-html';
import { Languages } from '@app/components/Languages';
import { getTranslations } from '@app/api/general.api';
import * as S from './UpdateFormPage.styles';

interface UpdateFormDataType extends FormModel {
  statusRaw: boolean;
  isHome: boolean;
  files: File[];
}

const UpdateFormPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formId } = useParams<{ formId?: string }>();

  const [form] = BaseForm.useForm();

  const { formData, isLoadingFormData, isActionLoading } = useAppSelector((state) => state.form);
  const general = useAppSelector((state) => state.general.general);

  const [thumbnail, setThumbnail] = useState<string | undefined>();
  const [translations, setTranslations] = useState({});
  const [lang, setlang] = useState('en');
  const [isTranslationMode, setIsTranslationMode] = useState<boolean>(true);

  const fields = useMemo(() => {
    return formData?.fields ?? [];
  }, [formData?.fields]);

  useEffect(() => {
    if ((!formData || formData.id !== formId) && formId) {
      dispatch(doGetFormDetail(formId))
        .unwrap()
        .then((data) => {
          if (data) {
            form.setFieldsValue({ statusRaw: data.status === 'public', isHome: data.pages?.includes('home') ?? false });
          }
        });
    }
    fetchTranslations();
  }, []);

  useEffect(() => {
    form.resetFields();
    setIsTranslationMode(lang !== 'settings');

    if (lang !== 'en') {
      const values = translations?.[lang] ?? {};
      form.setFieldsValue({ ...values });
    }
  }, [lang, translations]);

  useEffect(() => {
    if (!isTranslationMode) {
      initialThumbnail();
    }
  }, [lang, formData, general]);

  const initialThumbnail = () => {
    const thumbnailUrl = getFileUrl(formData?.thumbnail);
    setThumbnail(thumbnailUrl);
  };

  const fetchTranslations = async () => {
    if (formId) {
      const translations = await getTranslations('/forms', formId);
      setTranslations(translations);
      setlang('en');
    }
  };

  const getSanitized = (html: string): string => {
    return sanitizeHtml(html, {
      allowedAttributes: {
        '*': ['style'],
      },
      allowedClasses: {
        '*': ['ql*'],
      },
      allowedStyles: {
        '*': {
          'font-size': [/em/, /px/],
          'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
        },
      },
    });
  };

  const handleSubmit = (values: UpdateFormDataType) => {
    if (!formId) {
      return;
    }

    if (isTranslationMode) {
      dispatch(
        doUpdateForm({
          id: formId,
          data: {
            lang,
            name: values.name,
            preText: values.preText ? getSanitized(values.preText) : undefined,
            postText: values.postText ? getSanitized(values.postText) : undefined,
            submissionMessage: values.submissionMessage ? getSanitized(values.submissionMessage) : undefined,
            subtitle: values.subtitle,
            buttonText: values.buttonText,
            fields: formData?.fields ?? [],
          },
        }),
      )
        .unwrap()
        .then((res) => {
          if (res) {
            notificationController.success({
              message: 'Update form',
              description: 'Successfully',
            });
          } else {
            notificationController.error({
              message: 'Update form',
              description: 'Failed',
            });
          }
          fetchTranslations();
        })
        .catch((err) => {
          // notificationController.error({ message: err.message });
        });

      return;
    }

    const _thumbnailFile: File | any = values.files?.[0];
    dispatch(
      doUpdateForm({
        id: formId,
        data: {
          order: values.order,
          status: values.statusRaw ? 'public' : 'draft',
          pages: values.isHome ? ['home'] : [],
          submissionReceivers: values.submissionReceivers,
          fields: formData?.fields ?? [],
        },
        thumbnail: _thumbnailFile?.originFileObj ?? _thumbnailFile,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res) {
          notificationController.success({
            message: 'Update form',
            description: 'Successfully',
          });
          // dispatch(doCheckHomeForms());
        } else {
          notificationController.error({
            message: 'Update form',
            description: 'Failed',
          });
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file } = options;

    // const fmData = new FormData();
    let _thumbnail = file;
    try {
      _thumbnail = await resizeThumbnail(file);
    } catch (error) {
      console.log('[resizeThumbnail] error:', error);
      // form.setFieldsValue({ files: [file] });
    }

    form.setFieldsValue({ files: [_thumbnail] });

    setThumbnail(URL.createObjectURL(file));

    // console.log((fmData.get('file') as File).name);

    try {
      // const res = await axios.post(`${process.env.REACT_APP_BASE_URL}files/upload`, fmData, config);
      onSuccess('Ok');
      // form.setFieldsValue({ files: [fmData.get('file')] });
    } catch (err) {
      onError({ err });
    }
  };

  const renderFormItems = () => {
    if (isTranslationMode) {
      return (
        <>
          <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
            <Input />
          </BaseForm.Item>
          <BaseForm.Item name="preText" label="Form pre-text">
            <TextEditor />
          </BaseForm.Item>
          <BaseForm.Item name="postText" label="Form post text">
            <TextEditor />
          </BaseForm.Item>
          <BaseForm.Item name="submissionMessage" label="Post Submission Message">
            <TextEditor />
          </BaseForm.Item>
          <BaseForm.Item name="subtitle" label="Subtitle">
            <TextArea rows={4} />
          </BaseForm.Item>
          <BaseForm.Item name="buttonText" label="Button Text">
            <Input />
          </BaseForm.Item>
        </>
      );
    }

    return (
      <>
        <BaseForm.Item
          name="files"
          label="Form Icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: false }]}
        >
          <Upload
            accept="image/*"
            maxCount={1}
            customRequest={dummyRequest}
            listType="picture"
            onRemove={(file) => initialThumbnail()}
          >
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
            <S.Thumbnail preview={false} width={150} src={thumbnail} />
          </Upload>
        </BaseForm.Item>
        <S.HintText>{t('thumbnailUpload.uploadHint')}</S.HintText>
        <Row gutter={[64, 0]}>
          <Col>
            <BaseForm.Item
              name="order"
              label="Order in Home"
              rules={[{ required: false }, { type: 'integer', min: 0 }]}
            >
              <InputNumber />
            </BaseForm.Item>
          </Col>
          <Col>
            <BaseForm.Item name="statusRaw" label="Status" valuePropName="checked">
              <Switch />
            </BaseForm.Item>
          </Col>
        </Row>
        <BaseForm.Item name="submissionReceivers" label="Send Email To">
          <Input />
        </BaseForm.Item>
        <S.HintText>{t('common.submissionReceiversHint')}</S.HintText>
      </>
    );
  };

  return (
    <>
      <S.TitleRow gutter={[32, 32]} align={'middle'}>
        <Col>
          <S.Btn icon={<LeftOutlined />} type="text" onClick={() => navigate('/form/list')}>
            {t('common.back')}
          </S.Btn>
        </Col>
        <Col>
          <span>{formData?.name}</span>
        </Col>
      </S.TitleRow>
      <Card padding="1rem 1.875rem" loading={isLoadingFormData}>
        <Languages selected={lang} extraOptions={[{ code: 'settings', name: 'Settings' }]} onChange={setlang} />
        <BaseForm
          initialValues={
            {
              ...formData,
              statusRaw: (formData?.status ?? 'draft') === 'public',
              isHome: formData?.pages ? formData.pages.includes('home') : false,
            } ?? {}
          }
          form={form}
          layout="vertical"
          name="UpdateFormContent"
          onFinish={handleSubmit}
          disabled={isActionLoading}
        >
          {renderFormItems()}
        </BaseForm>

        {isTranslationMode && (
          <>
            <S.SectionText>{t('forms.updateForm.formInformation')}</S.SectionText>
            <S.HintText>{t('common.previewPurposesOnly')}</S.HintText>
            <S.CustomDiv>
              <FieldsPreview lang={lang} fields={fields} />
            </S.CustomDiv>
          </>
        )}

        <Space style={{ width: '100%', justifyContent: 'center' }} align="center">
          <S.SaveButton type="primary" htmlType="button" loading={isActionLoading} onClick={() => form.submit()}>
            {t('common.save')}
          </S.SaveButton>
        </Space>
      </Card>
    </>
  );
};

export default UpdateFormPage;
