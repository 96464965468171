import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { MinusCircleOutlined } from '@ant-design/icons';

export const Description = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xxs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  margin-top: 1.125rem;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const RemoveBtn = styled(MinusCircleOutlined)`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 2px;
`;

export const Text = styled.span`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;

export const Label = styled(Text)`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.medium};
`;

export const HintText = styled(Typography)`
  color: var(--subtext-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;
