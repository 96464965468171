import React, { useEffect, useState } from 'react';
import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { resizeThumbnail } from '@app/utils/utils';
import { doCreateCoupon } from '@app/store/slices/couponSlice';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { CreateCouponRequest } from '@app/api/coupon.api';
import { DateRangePicker } from '@app/components/common/pickers/DateRangePicker';
import { Dates } from '@app/constants/Dates';
import * as S from './CreateCouponModal.styles';

interface CreateCouponModalProps {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
  openCreateModelModal: () => void;
}
interface NewFormData {
  name: string;
  eligibleModels: string[];
  maxCodesPerDevice: number;
  thumbnail: File[];
  instruction: File[];
  codes: File[];
  subtitle: string;
  buttonText: string;
}

const clearDate = Dates.getToday().hour(0).minute(0).second(0).millisecond(0);

export const CreateCouponModal: React.FC<CreateCouponModalProps> = ({
  open,
  onCancel,
  onFinish,
  openCreateModelModal,
}) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { models, isModelsLoading, isActionLoading } = useAppSelector((state) => state.model);

  const [isLoading, setLoading] = useState(false);

  const [timeRange, setTimeRange] = useState<number[]>([]);

  useEffect(() => {
    if (open) {
      setTimeRange([]);
    }
  }, [open]);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: NewFormData) => {
    // console.log(values);
    // return;
    if (!timeRange[0] || !timeRange[1]) {
      notificationController.error({
        message: 'Date of start and end',
        description: t('common.requiredField'),
      });
      return;
    }
    const _thumbnailFile: File | any = values.thumbnail[0];
    const _instructionFile: File | any = values.instruction[0];
    const _codesFile: File | any = values.codes[0];
    // console.log(_file);

    if (_codesFile) {
      // read codes and parse to array
      setLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const _textRaw = e.target?.result as string | undefined | null;

        let _codes: string[] | undefined = [];
        // console.log(_textRaw);

        _codes = _textRaw?.split('\n');

        // submit data
        const _data: CreateCouponRequest = {
          name: values.name,
          maxCodesPerDevice: values.maxCodesPerDevice,
          eligibleModels: values.eligibleModels,
          startedAt: clearDate.add(timeRange[0]).toDate(),
          expiredAt: clearDate.add(timeRange[1]).toDate(),
          availableCodes: _codes ?? [],
          subtitle: values.subtitle,
          buttonText: values.buttonText,
        };
        dispatch(
          doCreateCoupon({
            data: _data,
            thumbnail: _thumbnailFile?.originFileObj ?? _thumbnailFile,
            instruction: _instructionFile?.originFileObj ?? _instructionFile,
          }),
        )
          .unwrap()
          .then((res) => {
            setLoading(false);
            if (res) {
              notificationController.success({
                message: 'Create new coupon',
                description: 'Successfully',
              });
              onFinish();
            } else {
              notificationController.error({
                message: 'Create new coupon',
                description: 'Failed',
              });
            }
          })
          .catch((err) => {
            // notificationController.error({ message: err.message });
            setLoading(false);
          });
      };
      reader.onerror = (evt) => {
        setLoading(false);
      };
      reader.readAsText(_codesFile?.originFileObj ?? _codesFile);
    }
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const thumbnailDummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    let _thumbnail = file;
    try {
      _thumbnail = await resizeThumbnail(file, 56, 56);
    } catch (error) {
      console.log('[resizeThumbnail] error:', error);
    }

    form.setFieldsValue({ thumbnail: [_thumbnail] });

    try {
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  const instructionDummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    const _instruction = file;
    // try {
    //   _instruction = await resizeThumbnail(file);
    // } catch (error) {
    //   console.log('[resizeThumbnail] error:', error);
    // }

    form.setFieldsValue({ instruction: [_instruction] });

    try {
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  const codesDummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    const _codes = file;

    form.setFieldsValue({ codes: [_codes] });

    try {
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <Modal
      title="Create Coupon"
      open={open}
      onOk={onOk}
      onCancel={onFormCancel}
      confirmLoading={isModelsLoading || isActionLoading || isLoading}
    >
      <BaseForm form={form} layout="vertical" name="CreateCoupon" onFinish={handleSubmit}>
        <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="subtitle"
          label="Subtitle"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <TextArea rows={2} />
        </BaseForm.Item>
        <BaseForm.Item
          name="thumbnail"
          label="Coupon Icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: false }]}
        >
          <Upload accept="image/*" maxCount={1} customRequest={thumbnailDummyRequest} listType="picture">
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <S.HintText>{t('thumbnailUpload.uploadHint')}</S.HintText>
        <BaseForm.Item
          name="eligibleModels"
          label="Eligible Models"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Select
            mode="multiple"
            allowClear
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <S.NewModelButton
                    icon={<PlusOutlined />}
                    type="text"
                    onClick={() => {
                      openCreateModelModal();
                    }}
                  >
                    Create new model
                  </S.NewModelButton>
                </>
              );
            }}
          >
            {models.map((item) => (
              <Option key={item.id} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </BaseForm.Item>
        <BaseForm.Item
          initialValue={1}
          name="maxCodesPerDevice"
          label="Max codes per device"
          rules={[
            { required: true, message: t('common.requiredField') },
            { type: 'integer', min: 1 },
          ]}
        >
          <InputNumber />
        </BaseForm.Item>
        <BaseForm.Item
          label={'Date of start and end'}
          required={true}
          htmlFor="date"
          // rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <DateRangePicker id={'date'} timeRange={timeRange} setTimeRange={setTimeRange} />
        </BaseForm.Item>
        {/* <BaseForm.Item
          name="expiredAt"
          label={'Expired at'}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Picker  />
        </BaseForm.Item> */}
        <BaseForm.Item
          name="codes"
          label="Bulk Upload Codes"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[
            // { required: true, message: t('common.requiredField') },
            {
              validator: async (_, codes) => {
                if (!codes || codes.length < 1) {
                  return Promise.reject(new Error(t('common.requiredField')));
                }
              },
            },
          ]}
        >
          <Upload accept="text/plain" maxCount={1} customRequest={codesDummyRequest} listType="text">
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <BaseForm.Item
          name="instruction"
          label="Instruction"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: false }]}
        >
          <Upload accept="image/*,.pdf" maxCount={1} customRequest={instructionDummyRequest} listType="text">
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <BaseForm.Item
          name="buttonText"
          label="Button Text"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
