import React from 'react';
import { Avatar, Col, Row } from 'antd';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import defaultAvatar from '@app/assets/images/default-avatar.jpg';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  return (
    <Popover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        {user ? (
          <>
            <Col>
              <Avatar src={defaultAvatar} alt="User" shape="circle" size={40} />
            </Col>
            {/* {isTablet && (
              <Col>
                <H6>{user.displayName && user.displayName !== '' ? user.displayName : user.email ?? ''}</H6>
              </Col>
            )} */}
          </>
        ) : (
          <Col>
            <Avatar src={defaultAvatar} alt="User" shape="circle" size={40} />
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Popover>
  );
};
