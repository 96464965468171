import React, { useEffect, useMemo } from 'react';
import { Modal, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { CouponTableRow } from '@app/api/coupon.api';
import { Dates } from '@app/constants/Dates';

interface CouponDetailModalProps {
  data?: CouponTableRow;
  open: boolean;
  onCancel: () => void;
}

export const CouponDetailModal: React.FC<CouponDetailModalProps> = ({ data, open, onCancel }) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();

  const general = useAppSelector((state) => state.general.general);
  const { models } = useAppSelector((state) => state.model);

  const dateNow = useMemo(() => Date.now(), []);

  useEffect(() => {
    if (open && data) {
      const _eligibleModels: string[] = [];
      const _objectValues = Object.values(data.eligibleModels);
      Object.keys(data.eligibleModels).map((_key, index) => {
        const _value = _objectValues[index];
        const _model = models.find((_item) => _item.name === _key);
        if (_model && _value === true) {
          _eligibleModels.push(_model.label);
        }
      });
      const _data = {
        eligibleModels: _eligibleModels,
      };

      form.setFieldsValue({ ..._data });
    }
  }, [open, data]);

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const _thumbnail = useMemo(
    () =>
      general?.publicPath && data?.thumbnail && data.thumbnail !== ''
        ? `${general?.publicPath}/${data.thumbnail}?alt=media&xplorehash=${dateNow}`
        : undefined,
    [data?.thumbnail, general],
  );

  const _instruction = useMemo(
    () =>
      general?.publicPath && data?.instructions && data.instructions !== ''
        ? `${general?.publicPath}/${data.instructions}?alt=media&xplorehash=${dateNow}`
        : undefined,
    [data?.instructions, general],
  );

  return (
    <Modal title="Coupon Detail" open={open} onOk={onFormCancel} onCancel={onFormCancel}>
      <BaseForm form={form} layout="vertical">
        <BaseForm.Item label="Name">{data?.name}</BaseForm.Item>
        <BaseForm.Item label="Subtitle">{data?.subtitle}</BaseForm.Item>
        <BaseForm.Item name="thumbnail" label="Coupon Icon">
          <Image preview={false} width={250} src={_thumbnail} />
        </BaseForm.Item>
        <BaseForm.Item name="eligibleModels" label="Eligible Models" initialValue={data}>
          <Select mode="multiple" disabled>
            {models.map((item) => (
              <Option key={item.id} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </BaseForm.Item>
        <BaseForm.Item initialValue={1} label="Max codes per device">
          {data?.maxCodesPerDevice}
        </BaseForm.Item>
        <BaseForm.Item label="Started at">
          {data?.startedAt ? Dates.getDate(data.startedAt).format('DD/MM/YYYY, HH:mm:ss') : ''}
        </BaseForm.Item>
        <BaseForm.Item label="Expired at">
          {data?.expiredAt ? Dates.getDate(data.expiredAt).format('DD/MM/YYYY, HH:mm:ss') : ''}
        </BaseForm.Item>
        <BaseForm.Item label="Codes">
          <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
            <span style={{ whiteSpace: 'pre-line' }}>{data?.availableCodes.join('\n')}</span>
          </div>
        </BaseForm.Item>
        {/* <BaseForm.Item
          name="codes"
          label="Bulk Upload Codes"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          // rules={[
          //   { required: true, message: t('common.requiredField') },
          //   {
          //     validator: async (_, codes) => {
          //       if (!codes || codes.length < 1) {
          //         return Promise.reject(new Error(t('common.requiredField')));
          //       }
          //     },
          //   },
          // ]}
        >
          <Upload accept="text/plain" maxCount={1} customRequest={codesDummyRequest} listType="text">
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item> */}
        {/* TODO: change preview image to preview file orimage */}
        <BaseForm.Item key="instruction" label="Instruction">
          {/* <Image preview={false} width={250} src={_instruction} /> */}
          {_instruction ? 'Yes' : 'No'}
        </BaseForm.Item>
        <BaseForm.Item label="Button Text">{data?.buttonText}</BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};
