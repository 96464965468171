import React, { useEffect, useState } from 'react';
import { Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useResetFormOnCloseModal } from '@app/components/forms/ControlForm/useResetFormOnCloseModal';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Upload } from '@app/components/common/Upload/Upload';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { resizeThumbnail } from '@app/utils/utils';
import { doUpdateCoupon } from '@app/store/slices/couponSlice';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { CouponTableRow, UpdateCouponRequest } from '@app/api/coupon.api';
import { DateRangePicker } from '@app/components/common/pickers/DateRangePicker';
import { AppDate, Dates } from '@app/constants/Dates';
import * as S from './UpdateCouponModal.styles';
import { getTranslations } from '@app/api/general.api';
import { Languages } from '@app/components/Languages';

interface UpdateCouponModalProps {
  data?: CouponTableRow;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
  openCreateModelModal: () => void;
}
interface UpdateCouponData {
  name: string;
  // startedAt: dayjs.Dayjs;
  // expiredAt: dayjs.Dayjs;
  eligibleModels: string[];
  maxCodesPerDevice: number;
  thumbnail: File[];
  instruction: File[];
  codes: string;
  subtitle: string;
  buttonText: string;
}

const clearDate = Dates.getToday().hour(0).minute(0).second(0).millisecond(0);
const clearDateMs = +clearDate;

export const UpdateCouponModal: React.FC<UpdateCouponModalProps> = ({
  data,
  open,
  onCancel,
  onFinish,
  openCreateModelModal,
}) => {
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { models, isModelsLoading, isActionLoading } = useAppSelector((state) => state.model);

  const [isLoading, setLoading] = useState(false);

  const [timeRange, setTimeRange] = useState<number[]>([]);

  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en');
  const [isTranslationMode, setIsTranslationMode] = useState<boolean>(true);

  useEffect(() => {
    if (open && data) {
      initialData();
      const timeRangeSinceTodayMs = [data.startedAt, data.expiredAt]
        .map((time: string) => Dates.getDate(time).subtract(clearDateMs, 'ms'))
        .map((time: AppDate) => +time);
      setTimeRange(timeRangeSinceTodayMs);

      fetchTranslations();
    }
  }, [open, data]);

  const initialData = () => {
    if (data) {
      const _eligibleModels: string[] = [];
      const _objectValues = Object.values(data.eligibleModels);
      Object.keys(data.eligibleModels).map((_key, index) => {
        const _value = _objectValues[index];
        const _model = models.find((_item) => _item.name === _key);
        if (_model && _value === true) {
          _eligibleModels.push(_model.label);
        }
      });
      const _data = {
        eligibleModels: _eligibleModels,
        maxCodesPerDevice: data.maxCodesPerDevice,
        codes: data.availableCodes.join('\n'),
      };
      form.setFieldsValue({ ..._data });
    }
  };

  useEffect(() => {
    form.resetFields();
    setIsTranslationMode(language !== 'settings');

    if (language === 'settings') {
      initialData();
    } else {
      const translation = translations?.[language] ?? {};
      const { instructions, ...values } = translation;

      form.setFieldsValue({ ...values });
    }
  }, [language, translations]);

  const fetchTranslations = async () => {
    if (data && data.id) {
      const translations = await getTranslations('/coupons', data.id);
      setTranslations(translations);
      setLanguage('en');
    }
  };
  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    form.submit();
  };

  const onFormCancel = async () => {
    onCancel && onCancel();
  };

  const handleSubmit = (values: UpdateCouponData) => {
    if (!timeRange[0] || !timeRange[1]) {
      notificationController.error({
        message: 'Date of start and end',
        description: t('common.requiredField'),
      });
      return;
    }
    if (!data?.id) {
      return;
    }

    const _thumbnailFile: File | any = values.thumbnail?.[0];
    const _instructionFile: File | any = values.instruction?.[0];

    let _data: UpdateCouponRequest = {
      language,
      name: values.name,
      subtitle: values.subtitle,
      buttonText: values.buttonText,
    };

    if (!isTranslationMode) {
      const _codes = values.codes.split('\n');
      _data = {
        maxCodesPerDevice: values.maxCodesPerDevice,
        eligibleModels: values.eligibleModels,
        startedAt: clearDate.add(timeRange[0]).toDate(),
        expiredAt: clearDate.add(timeRange[1]).toDate(),
        availableCodes: _codes,
      };
    }

    handleUpdateCoupon({
      id: data.id,
      data: _data,
      thumbnail: _thumbnailFile?.originFileObj ?? _thumbnailFile,
      instruction: _instructionFile?.originFileObj ?? _instructionFile,
    });
  };

  const handleUpdateCoupon = async (payload: {
    id: string;
    data: UpdateCouponRequest;
    thumbnail?: File;
    instruction?: File;
  }) => {
    setLoading(true);
    dispatch(doUpdateCoupon(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res) {
          notificationController.success({
            message: 'Update coupon',
            description: 'Successfully',
          });
          onFinish();
        } else {
          notificationController.error({
            message: 'Update coupon',
            description: 'Failed',
          });
        }
      })
      .catch((err) => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const normFile = (e = { fileList: [] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const thumbnailDummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    let _thumbnail = file;
    try {
      _thumbnail = await resizeThumbnail(file, 100, 100);
    } catch (error) {
      console.log('[resizeThumbnail] error:', error);
    }

    form.setFieldsValue({ thumbnail: [_thumbnail] });

    try {
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  const instructionDummyRequest = async (options: { file: any; onSuccess?: any; onError?: any; onProgress?: any }) => {
    const { onSuccess, onError, file, onProgress } = options;

    const _instruction = file;
    // try {
    //   _instruction = await resizeThumbnail(file);
    // } catch (error) {
    //   console.log('[resizeThumbnail] error:', error);
    // }

    form.setFieldsValue({ instruction: [_instruction] });

    try {
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  const renderFormItems = () => {
    if (isTranslationMode) {
      return (
        <>
          <BaseForm.Item name="name" label="Name" rules={[{ required: true, message: t('common.requiredField') }]}>
            <Input />
          </BaseForm.Item>
          <BaseForm.Item
            name="subtitle"
            label="Subtitle"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <TextArea rows={2} />
          </BaseForm.Item>
          <BaseForm.Item
            name="buttonText"
            label="Button Text"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Input />
          </BaseForm.Item>
          <BaseForm.Item
            name="instruction"
            label="Instruction"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            initialValue={[]}
            rules={[{ required: false }]}
          >
            <Upload accept="image/*,.pdf" maxCount={1} customRequest={instructionDummyRequest} listType="text">
              <Button type="default" icon={<UploadOutlined />}>
                {t('forms.validationFormLabels.clickToUpload')}
              </Button>
            </Upload>
            <S.HintText>File Path: {translations[language]?.instructions}</S.HintText>
          </BaseForm.Item>
        </>
      );
    }

    return (
      <>
        <BaseForm.Item
          name="thumbnail"
          label="Coupon Icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          initialValue={[]}
          rules={[{ required: false }]}
        >
          <Upload accept="image/*" maxCount={1} customRequest={thumbnailDummyRequest} listType="picture">
            <Button type="default" icon={<UploadOutlined />}>
              {t('forms.validationFormLabels.clickToUpload')}
            </Button>
          </Upload>
        </BaseForm.Item>
        <S.HintText>{t('thumbnailUpload.uploadHint')}</S.HintText>
        <BaseForm.Item
          name="eligibleModels"
          label="Eligible Models"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Select
            mode="multiple"
            allowClear
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <S.NewModelButton
                    icon={<PlusOutlined />}
                    type="text"
                    onClick={() => {
                      openCreateModelModal();
                    }}
                  >
                    Create new model
                  </S.NewModelButton>
                </>
              );
            }}
          >
            {models.map((item) => (
              <Option key={item.id} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </BaseForm.Item>
        <BaseForm.Item
          initialValue={1}
          name="maxCodesPerDevice"
          label="Max codes per device"
          rules={[
            { required: true, message: t('common.requiredField') },
            { type: 'integer', min: 1 },
          ]}
        >
          <InputNumber />
        </BaseForm.Item>
        <BaseForm.Item label={'Date of start and end'} required={true}>
          <DateRangePicker timeRange={timeRange} setTimeRange={setTimeRange} />
        </BaseForm.Item>
        <BaseForm.Item name="codes" label="Codes" rules={[{ required: true, message: t('common.requiredField') }]}>
          <TextArea rows={10} />
        </BaseForm.Item>
      </>
    );
  };

  return (
    <Modal
      title="Update Coupon"
      open={open}
      onOk={onOk}
      onCancel={onFormCancel}
      confirmLoading={isModelsLoading || isActionLoading || isLoading}
    >
      <BaseForm form={form} layout="vertical" name="UpdateCoupon" onFinish={handleSubmit}>
        <Languages selected={language} extraOptions={[{ name: 'Settings', code: 'settings' }]} onChange={setLanguage} />
        {renderFormItems()}
      </BaseForm>
    </Modal>
  );
};
